import { useEffect } from 'react'
import FilmStripIcon from 'share-svgs/svgs/film-strip.svg'
import { Button } from 'ui'

const BUTTON_WIDTH = 200
const BUTTON_HEIGHT = 50
const ASIDE_WIDTH = 200
const BOUNDING_WIDTH_OFFSET = 150
const BOUNDING_HEIGHT_OFFSET = 30
const OFFSET_FOR_FIRST_SPAN = 40
const INVISIBLE_ELEMENT_ADJUSTMENT = 380
const LINE_HEIGHT_OFFSET = 30
const SCROLL_BAR_WIDTH_ADJUSTMENT = 200

export const SelectionPopup = ({
    createHighlightHandler
}: {
    createHighlightHandler(): void
}) => {
    const getBoundingAdjustment = (
        commonAncestorBoundingRight: number,
        commonAncestorBoundingBottom: number,
        originalButtonLeft: number,
        originalButtonTop: number
    ) => {
        let updatedButtonLeft = originalButtonLeft
        let updatedButtonTop = originalButtonTop

        if (
            commonAncestorBoundingRight -
                (originalButtonLeft +
                    ASIDE_WIDTH +
                    SCROLL_BAR_WIDTH_ADJUSTMENT) <
            BUTTON_WIDTH
        ) {
            updatedButtonLeft -= BOUNDING_WIDTH_OFFSET
        }
        if (updatedButtonTop + BUTTON_HEIGHT > commonAncestorBoundingBottom) {
            updatedButtonTop -= BOUNDING_HEIGHT_OFFSET
        }

        return { updatedButtonLeft, updatedButtonTop }
    }

    const updateButtonPosition = () => {
        let selection = window.getSelection()
        let button = document.getElementById('selectionButton')

        // console.log('selection', selection)
        // Use a timeout to delay the update until after the selection has been possibly modified by the click.
        setTimeout(() => {
            // make sure button exist and selection is valid and not collapsed
            if (
                button &&
                selection &&
                selection.type !== 'Caret' &&
                selection.type !== 'None' &&
                selection?.rangeCount > 0 &&
                selection.toString().trim() !== ''
            ) {
                const range = selection.getRangeAt(0)
                let commonAncestor =
                    range.commonAncestorContainer as HTMLElement

                if (
                    commonAncestor &&
                    commonAncestor.nodeType === Node.ELEMENT_NODE
                ) {
                    // if doing a highlight and selecting text that is entirely contained in one single monologue, we need to go to the grandparent node to grab the transcription containing div (if selecting text across multiple monologues, the containing div is just the common ancestor)
                    if (commonAncestor.nodeName === 'P') {
                        commonAncestor = commonAncestor.parentElement
                            ?.parentElement as HTMLElement
                    }
                    // If this isn't left optional sometimes it can bug everything out, I am not sure why since we have the conditionals above, but the page is more stable if this is left optional i.e. commonAncestor?
                    const boundingRect = commonAncestor?.getBoundingClientRect()

                    // get the true bottom position from the last monologues positioning
                    const monologuesPTags =
                        commonAncestor?.querySelectorAll('p')
                    const lastMonologue =
                        monologuesPTags[monologuesPTags.length - 1]

                    const trueBoundingBottom =
                        lastMonologue?.offsetTop + lastMonologue?.offsetHeight

                    // grab start node and end node of the selection and confirm they are HTML elements
                    const startNode = selection.anchorNode?.parentNode
                    const endNode = selection.focusNode?.parentNode

                    if (
                        startNode &&
                        startNode instanceof HTMLElement &&
                        endNode &&
                        endNode instanceof HTMLElement
                    ) {
                        const startNodeLeft = startNode.offsetLeft
                        const startNodeTop = startNode.offsetTop
                        const endNodeLeft = endNode.offsetLeft
                        const endNodeTop = endNode.offsetTop

                        // console.log('end Node', endNode)

                        // console.log('end Node left', endNode.offsetLeft)
                        // console.log('end Node Top', endNode.offsetTop)
                        button.style.position = 'absolute' // Use absolute positioning within the document
                        button.style.display = 'block' // Make the button visible

                        // if you are selecting down and stop short of the next monologue down and release the mouse to the right of the next monolgue speaker label down, an edge case bug often occurs where the endNode becomes the entire next div / p tag, this causes trouble because we typically position the button at the end node when it is a span
                        // This conditonal checks for that and addresses this by positioning the button at the last span of the previous p tag (monologue)
                        if (endNode.nodeName === 'DIV') {
                            const prevNode =
                                endNode.previousSibling as HTMLElement
                            const prevNodeSpans =
                                prevNode?.querySelectorAll('span')
                            if (prevNodeSpans) {
                                const lastSpan =
                                    prevNodeSpans[prevNodeSpans.length - 1]

                                // make sure the button is in bounds of the containing div using lastSpan location as original reference
                                const { updatedButtonLeft, updatedButtonTop } =
                                    getBoundingAdjustment(
                                        boundingRect.right,
                                        trueBoundingBottom,
                                        lastSpan.offsetLeft,
                                        lastSpan.offsetTop
                                    )
                                // console.log(
                                //     'selection direction: down - multi-line - edge case: end-node is an entire monologue'
                                // )
                                button.style.left = `${updatedButtonLeft}rem`
                                button.style.top = `${updatedButtonTop}rem`
                            }
                            return
                        }

                        // make sure the button is in bounds of the containing div for all other scenarios
                        const { updatedButtonLeft, updatedButtonTop } =
                            getBoundingAdjustment(
                                boundingRect.right,
                                trueBoundingBottom,
                                endNodeLeft,
                                endNodeTop
                            )

                        // Selection direction down - multi line, normal behavior with no edge cases
                        if (startNodeTop < endNodeTop) {
                            // console.log(
                            //     'selection direction: down - multi-line - no edge cases'
                            // )
                            button.style.left = `${updatedButtonLeft}rem`
                            button.style.top = `${updatedButtonTop}rem`
                            return
                        }

                        // Selection direction up
                        if (startNodeTop > endNodeTop) {
                            // Sometimes when selecting up if you stop short of selecting the next monologue up but still click near that above monologue, you can cause an edge case bug, this bug can also occur if you select just to the right of monologue text in the right spot
                            if (
                                // This conditional checks whether the selection is across multiple monologues
                                commonAncestor.nodeName === 'DIV' &&
                                // This conditional checks the edge case wherein an invisble element is selected
                                endNode.offsetWidth === 0
                            ) {
                                // This conditional checks if you selected text and released the mouse just to the right of the monologue and have an end node that is an invisible element but this endNode is in the middle of the monologue and not at the end
                                if (endNode.nextElementSibling) {
                                    const nextSibling =
                                        endNode?.nextElementSibling as HTMLElement | null
                                    if (nextSibling) {
                                        // console.log(
                                        //     'selection direction: up - multi-line - edge case: end-node is invisible and in middle of monologue'
                                        // )
                                        // add offset so button appears closer to where user releases their mouse
                                        button.style.left = `${
                                            nextSibling.offsetLeft +
                                            INVISIBLE_ELEMENT_ADJUSTMENT
                                        }rem`
                                        // Add offset so that button sits above text helping to communicate direction to user
                                        button.style.top = `${
                                            nextSibling.offsetTop -
                                            LINE_HEIGHT_OFFSET * 2
                                        }rem`
                                        return
                                    }
                                }
                                // If endNode, is not in the middle of a container, then this bug will occur at the end of a monologue above your selection, we will grab the first span of this monologue and the height of the monologue container and use that to position the button at the correct position below that monologue
                                // We do it this way because this also addresses the bug where the startNode is an entire div as mentioned previously
                                const endNodeContainer =
                                    endNode.parentElement?.parentElement
                                const containingNodeSpans =
                                    endNodeContainer?.querySelectorAll('span')
                                if (endNodeContainer && containingNodeSpans) {
                                    const firstSpan = containingNodeSpans[0]

                                    // console.log(
                                    //     'selection direction: up - multi-line - edge case: end-node is invisible and at end of above monologue'
                                    // )
                                    button.style.left = `${firstSpan.offsetLeft}rem`
                                    button.style.top = `${
                                        firstSpan.offsetTop +
                                        endNodeContainer.offsetHeight -
                                        OFFSET_FOR_FIRST_SPAN
                                    }rem`
                                    return
                                }
                            }
                            // normal scenario selecting up across multiple lines with no edge cases
                            // console.log(
                            //     'selection direction: up - multi-line - no edge cases'
                            // )
                            button.style.left = `${updatedButtonLeft}rem`
                            // Add offset so that button sits above text helping to communicate direction to user
                            button.style.top = `${
                                updatedButtonTop - LINE_HEIGHT_OFFSET * 2
                            }rem`
                            return
                        }

                        // Single line selection - no edge cases
                        if (startNodeTop === endNodeTop) {
                            // Selection direction down
                            if (startNodeLeft < endNodeLeft) {
                                // console.log(
                                //     'selection direction: down - single line'
                                // )
                                button.style.left = `${updatedButtonLeft}rem`
                                button.style.top = `${updatedButtonTop}rem`
                                return
                            }
                            // Selection direction up
                            if (startNodeLeft > endNodeLeft) {
                                // console.log(
                                //     'selection direction: up - single line'
                                // )
                                button.style.left = `${updatedButtonLeft}rem`
                                // Add offset so that button sits above text helping to communicate direction to user
                                button.style.top = `${
                                    updatedButtonTop - LINE_HEIGHT_OFFSET * 2
                                }rem`
                                return
                            }
                        }
                    }
                }
            } else {
                if (button) button.style.display = 'none'
            }
        }, 10)
    }

    const mouseDownHandler = (event: MouseEvent) => {
        if (!event) return
        let button = document.getElementById('selectionButton')
        if (button && !button.contains(event.target as Node | null)) {
            button.style.display = 'none'
        }
    }

    useEffect(() => {
        const parentDiv = document.getElementById('transcription-parent-div')

        parentDiv?.addEventListener('mouseup', updateButtonPosition)
        document.addEventListener('mousedown', mouseDownHandler)
        return () => {
            parentDiv?.removeEventListener('mouseup', updateButtonPosition)
            document.removeEventListener('mousedown', mouseDownHandler)
        }
    })

    return (
        <Button
            color={'primary'}
            size={'sm'}
            onClick={createHighlightHandler}
            id="selectionButton"
            css={{
                display: 'none',
                '& svg': {
                    height: '13rem',
                    position: 'relative',
                    top: '2rem'
                }
            }}
        >
            <FilmStripIcon />
            Create Highlight
        </Button>
    )
}
