import { keyframes } from '@stitches/react'
import { Fragment } from 'react'
import { styled } from '../../theme/stitches.config'

const pulse = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: '100%' }
})

export const Skeleton = styled('div', {
    $$backgroundColor: '#202326',
    $$backgroundLoadingColor: 'hsl (210 , 9, 14, 60%)',
    backgroundColor: '$$backgroundColor',
    borderRadius: '$1',
    position: 'relative',
    overflow: 'hidden',

    '&::after': {
        animationName: `${pulse}`,
        animationDuration: '500ms',
        animationDirection: 'alternate',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'ease-in-out',
        backgroundColor: '$$backgroundLoadingColor',
        borderRadius: 'inherit',
        bottom: 0,
        content: '""',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0
    },

    variants: {
        noRadius: {
            true: {
                borderRadius: 'initial'
            }
        }
    }
})

interface SkeletonProps {
    amount: number
    height: string
    width?: string
}

export const Skeletons = (skeleton: SkeletonProps) => {
    const skeletons = []
    for (let i = 0; i < skeleton.amount; i++) {
        skeletons.push(
            <Skeleton
                key={`skeleton-${i}`}
                css={{
                    height: skeleton.height,
                    width: skeleton.width || '100%'
                }}
            />
        )
    }
    return <Fragment>{skeletons.map((s) => s)}</Fragment>
}
