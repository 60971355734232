import { useEffect, useState } from 'react'
import { MultiValue, SingleValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'

// this is type select is expecting
type OptionType = {
    label: string
    value: string
}

// this is data for available options from BE
export type OptionData = {
    id: string
    name: string
}

type ComboboxProps = {
    placeholder: string
    options: OptionData[]
    isMultiSelect?: boolean
    value?: OptionData[]
    disabled?: boolean
    handleCreate(value: string): Promise<OptionData | undefined>
    onChange(value: string[] | string): void
}

export const Combobox = ({
    placeholder,
    isMultiSelect,
    options,
    value,
    disabled,
    handleCreate,
    onChange
}: ComboboxProps) => {
    const [optionsState, setOptionsState] = useState<OptionType[]>([])
    const [selectedOptions, setSelectedOptions] = useState<
        OptionType[] | OptionType | undefined
    >(isMultiSelect ? [] : undefined)

    const handleCreateNewOption = async (input: string) => {
        try {
            const newOption = await handleCreate(input)
            if (newOption) {
                const correctNewOption = {
                    label: newOption.name,
                    value: newOption.id
                }

                if (Array.isArray(selectedOptions)) {
                    const updatedOptions = [
                        ...selectedOptions,
                        correctNewOption
                    ]

                    setSelectedOptions(updatedOptions)
                    const result = updatedOptions.map(
                        (i: OptionType) => i.value
                    )
                    onChange(result)
                } else {
                    setSelectedOptions(correctNewOption)
                    onChange(correctNewOption.value)
                }
            }
        } catch (error) {
            alert(error)
        }
    }

    const handleOnChange = (
        input: MultiValue<OptionType> | SingleValue<OptionType>
    ) => {
        if (Array.isArray(input)) {
            // multiple select
            if (input.length > 0) {
                const result = input.map((i: OptionType) => i.value)
                setSelectedOptions(input)
                onChange(result)
            } else {
                setSelectedOptions(input)
                onChange([])
            }
        } else if (input !== null && typeof input === 'object') {
            // single select
            const singleInput = input as OptionType
            onChange(singleInput.value)
            setSelectedOptions(singleInput)
        } else {
            // clear select
            onChange('')
            setSelectedOptions(undefined)
        }
    }

    // getting selected value from backend, and transform into selected value select like
    useEffect(() => {
        if (value && value.length > 0) {
            const results: OptionType[] = []
            value.map((v) => {
                const result = { label: v.name, value: v.id }
                results.push(result)
            })
            setSelectedOptions(results)
        }
    }, [])

    // getting avaiblable options from backend and transform into shape Select like
    // whenever a new option is created, options state is updated
    useEffect(() => {
        if (options?.length > 0) {
            const result = options.map((p) => ({ label: p.name, value: p.id }))
            setOptionsState(result)
        }
    }, [options])

    return (
        <CreatableSelect
            placeholder={placeholder}
            isClearable
            isDisabled={disabled}
            isMulti={isMultiSelect}
            options={optionsState}
            value={selectedOptions}
            onCreateOption={handleCreateNewOption}
            isValidNewOption={(input) => input.length > 0 && input.length < 40}
            onChange={(options) => {
                handleOnChange(options)
            }}
            closeMenuOnSelect={!isMultiSelect}
            // keep menuIsOpen for development
            // menuIsOpen
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: '#0097A0',
                    backgroundColor: state.isDisabled ? '#6C6C6C' : '#3C4347',
                    cursor: 'pointer',
                    padding: '2rem 1rem',
                    borderRadius: '20rem',
                    '&:hover': {
                        borderColor: '#0097A0'
                    }
                }),
                indicatorsContainer: (baseStyles) => ({
                    ...baseStyles,
                    '& > :first-of-type': {
                        color: '#cccccc',
                        '&:hover': {
                            color: '#cccccc'
                        }
                    }
                }),
                dropdownIndicator: (baseStyles) => ({
                    ...baseStyles,
                    color: '#cccccc',
                    '&:hover': {
                        color: '#cccccc'
                    }
                }),
                input: (baseStyles) => ({
                    ...baseStyles,
                    color: 'white'
                }),
                option: (baseStyles) => ({
                    ...baseStyles,
                    color: 'white',
                    backgroundColor: '#3C4347',
                    '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: '#0097A0'
                    },
                    '&:active': {
                        backgroundColor: '#3C4347'
                    }
                }),
                menu: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: '#3C4347',
                    borderRadius: '14rem'
                }),
                menuList: (baseStyles) => ({
                    ...baseStyles,
                    maxHeight: '140rem',
                    backgroundColor: '#3C4347',
                    paddingBottom: '0',
                    paddingTop: '0',
                    border: '1px solid #0097A0',
                    borderRadius: '14rem'
                }),
                multiValue: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: 'rgba(0, 151, 160, 0.3)',
                    paddingBottom: '3rem',
                    borderRadius: '12rem',
                    '& > :first-of-type': {
                        color: 'white'
                    }
                }),
                singleValue: (baseStyles) => ({
                    ...baseStyles,
                    color: 'white'
                }),
                multiValueRemove: (baseStyles) => ({
                    ...baseStyles,
                    borderRadius: '8rem',
                    '&:hover': {
                        color: 'white',
                        backgroundColor: '#5878A5'
                    }
                })
            }}
        />
    )
}
