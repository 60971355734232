import { ReactNode } from 'react'
import { CSS, styled, VariantProps } from '../../theme/stitches.config'
import { HStack } from '../Layout'
import { Text } from '../Text'

const DEFAULT_TAG = 'div'

type BannerProps = {
    description: ReactNode
    svg: any
} & VariantProps<typeof BannerContainer> &
    React.ComponentProps<typeof DEFAULT_TAG> & { css?: CSS; as?: any }

const BannerContainer = styled('div', {
    $$customSpacing: '',
    width: '100%',
    borderRadius: '$1',
    display: 'flex',
    flexDirection: 'column',
    '& svg': { width: '25rem' },
    variants: {
        variant: {
            red: {
                border: '1rem solid $burnt',
                background: 'rgba(253, 23, 47, 0.10)',
                '& svg .warning_svg__fillColor': { fill: '$burnt' }
            },
            gold: {
                border: '1px solid $gold',
                background: 'rgba(183, 72, 72, 0.10)'
            },
            deep_aqua: {
                border: '1rem solid $deep_aqua',
                background: 'rgba(99, 185, 215, 0.10)',
                '& svg .warning_svg__fillColor': { fill: '$deep_aqua' }
            }
        },
        maxWidth: {
            sm: {
                maxWidth: '$sm'
            },
            md: {
                maxWidth: '$md'
            },
            lg: {
                maxWidth: '$lg'
            },
            max: {
                maxWidth: '100%'
            }
        },
        padding: {
            lg: {
                p: '$7'
            },
            md: {
                p: '$4'
            },
            sm: {
                p: '$3'
            },
            custom: {
                p: '$$customP'
            },
            subscription: {
                p: '$4 $3'
            }
        },
        p: {
            1: {
                p: '$1'
            },
            2: {
                p: '$2'
            },
            3: {
                p: '$3'
            },
            4: {
                p: '$4'
            },
            5: {
                p: '$5'
            },
            6: {
                p: '$6'
            },
            7: {
                p: '$7'
            },
            8: {
                p: '$8'
            },
            9: {
                p: '$9'
            }
        },
        spacing: {
            '1': {
                m: '$1 auto'
            },
            '2': {
                m: '$2 auto'
            },
            '3': {
                m: '$3 auto'
            },
            '4': {
                m: '$4 auto'
            },
            '5': {
                m: '$5 auto'
            },
            '6': {
                m: '$6 auto'
            },
            '7': {
                m: '$7 auto'
            },
            '8': {
                m: '$8 auto'
            },
            '9': {
                m: '$9 auto'
            },
            '10': {
                m: '$10 auto'
            },
            custom: {
                m: '$$customSpacing'
            }
        },
        noBottomMargin: {
            true: {
                mb: '0'
            }
        }
    },
    defaultVariants: {
        p: '2',
        maxWidth: 'sm'
    }
})

export const UsageBanner = ({
    title,
    description,
    svg,
    css,
    children,
    spaceBetween = false,
    ...props
}: BannerProps & { spaceBetween?: boolean }) => (
    <BannerContainer data-testid="usage-banner" {...props}>
        <HStack
            justifyContent={spaceBetween ? 'spaceBetween' : 'flexStart'}
            spacing={'1'}
        >
            <HStack widthAuto>
                {svg}
                <Text weight={'bold'} size={'2'}>
                    {description}
                </Text>
            </HStack>
            {children}
        </HStack>
    </BannerContainer>
)
