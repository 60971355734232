import * as ProgressPrimitive from '@radix-ui/react-progress'
import { ComponentProps, forwardRef } from 'react'
import { CSS, styled, VariantProps } from '../../theme/stitches.config'

const StyledProgressBarContainer = styled('div', {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
})

const StyledProgressBar = styled(ProgressPrimitive.Root, {
    $$progressBarHeight: '12rem',
    $$progressBarBackground: '#274955',
    $$progressBarRadius: '4rem',
    width: '100%',
    height: '$$progressBarHeight',
    borderRadius: '$$progressBarRadius',
    background: '$$progressBarBackground',
    boxSizing: 'border-box',
    position: 'relative',
    transform: 'translateZ(0)',
    overflow: 'hidden'
})

const ProgressBarIndicator = styled(ProgressPrimitive.Indicator, {
    $$progressBarIndicator: '$colors$deep_aqua',
    boxSizing: 'border-box',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: '$$progressBarIndicator',
    transition: 'transform 150ms cubic-bezier(0.65, 0, 0.35, 1)'
})

const PercentageDisplayDiv = styled('div', {
    ml: 'auto',
    fontSize: '$2',
    color: '$ivory'
})

type ProgressBarVariants = VariantProps<typeof StyledProgressBar>
type ProgressBarPrimitiveProps = ComponentProps<typeof ProgressPrimitive.Root>
type ProgressBarProps = ProgressBarPrimitiveProps &
    ProgressBarVariants & { css?: CSS } & { showPercentage?: boolean }

export const ProgressBar = forwardRef<
    React.ElementRef<typeof StyledProgressBar>,
    ProgressBarProps
>(({ value, max = 100, showPercentage, ...props }, forwardedRef) => {
    const percentage = value != null ? 100 - value : null

    return (
        <StyledProgressBarContainer>
            <StyledProgressBar
                {...props}
                ref={forwardedRef}
                value={value}
                max={max}
            >
                <ProgressBarIndicator
                    style={{ transform: `translateX(-${percentage}%)` }}
                />
            </StyledProgressBar>
            {showPercentage && (
                <PercentageDisplayDiv>
                    {value ? value : '0'} %
                </PercentageDisplayDiv>
            )}
        </StyledProgressBarContainer>
    )
})

ProgressBar.displayName = 'ProgressBar'
