import algoliasearch from 'algoliasearch/lite'
import { useAuth } from 'auth'
import singletonRouter, { NextRouter } from 'next/router'
import { ReactNode, useMemo } from 'react'
import { InstantSearch } from 'react-instantsearch'
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs'

export function InstantSearchWrapper({
    router,
    children
}: {
    router: NextRouter
    children: ReactNode
}) {
    const { user } = useAuth()
    const searchClient = useMemo(() => {
        return user?.data.search_key
            ? algoliasearch(
                  `${process.env.SEARCH_APPLICATION_ID}`,
                  user.data.search_key
              )
            : null
    }, [user])

    if (!searchClient) {
        return <>{children}</>
    }

    return (
        <InstantSearch
            searchClient={searchClient}
            indexName={process.env.SEARCH_INDEX_NAME}
            future={{ preserveSharedStateOnUnmount: true }}
            key={(router.query.query as string) || ''}
            routing={{
                router: createInstantSearchRouterNext({
                    singletonRouter,
                    routerOptions: {
                        cleanUrlOnDispose: false
                    }
                })
            }}
        >
            {children}
        </InstantSearch>
    )
}
