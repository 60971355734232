import { useRouter } from 'next/router'
import { Box, Text } from 'ui'
import { styled } from 'ui/theme/stitches.config'

export const LearnMoreSuccess = () => {
    const router = useRouter()

    return (
        <Box justifyContent={'center'} alignItems={'center'}>
            <Box
                css={{
                    maxWidth: '800px'
                }}
                p="6"
                justifyContent={'center'}
                alignItems={'center'}
                gap="9"
            >
                <Text
                    textTransform={'upper'}
                    css={{
                        fontSize: '50px',
                        fontWeight: 'bold'
                    }}
                    as={'h1'}
                >
                    thank you!
                </Text>
                <Text textAlign={'center'}>
                    Your submission has been sent and someone will be in contact
                    with you soon! Meanwhile, take a look around our website to
                    learn more about what we do and who&apos;ve we worked with.
                </Text>

                <StyledButton
                    css={{ background: '$magenta_300' }}
                    onClick={() => router.push('/')}
                >
                    return home
                </StyledButton>
            </Box>
        </Box>
    )
}

const StyledButton = styled('button', {
    backgroundColor: '$magenta_300',
    letterSpacing: '3rem',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '12px',
    borderRadius: '30px',
    border: 'none',
    cursor: 'pointer',
    textTransform: 'uppercase',
    gridColumn: 'span 2',
    width: '180px'
})
