import { useEffect } from 'react'

interface TouchPosition {
    touchStartY: number
}

export function usePreventScroll(isOpen: boolean): void {
    useEffect(() => {
        if (!isOpen) return

        const originalStyle: string = window.getComputedStyle(
            document.body
        ).overflow
        const scrollY: number = window.scrollY
        const touchPosition: TouchPosition = { touchStartY: 0 }

        const handleTouchStart = (e: TouchEvent): void => {
            if (e.touches[0]) {
                touchPosition.touchStartY = e.touches[0].clientY
            }
        }

        const handleTouchMove = (e: TouchEvent): void => {
            const touchY: number = e.touches[0]?.clientY ?? 0
            const modalElement: HTMLElement | null =
                document.querySelector('[role="dialog"]')

            if (!modalElement) return

            const isModalAtTop: boolean = modalElement.scrollTop === 0
            const isModalAtBottom: boolean =
                modalElement.scrollHeight - modalElement.scrollTop ===
                modalElement.clientHeight

            // Allow scrolling within modal content
            if (!isModalAtTop && !isModalAtBottom) return

            // Prevent bounce effect at top and bottom
            if (
                (isModalAtTop && touchY > touchPosition.touchStartY) ||
                (isModalAtBottom && touchY < touchPosition.touchStartY)
            ) {
                e.preventDefault()
            }
        }

        // Apply styles and event listeners
        const applyStyles = (): void => {
            document.body.style.overflow = 'hidden'
            document.body.style.position = 'fixed'
            document.body.style.width = '100%'
            document.body.style.top = `-${scrollY}px`
        }

        // Reset styles and scroll position
        const resetStyles = (): void => {
            document.body.style.overflow = originalStyle
            document.body.style.position = ''
            document.body.style.width = ''
            document.body.style.top = ''
            window.scrollTo(0, scrollY)
        }

        applyStyles()

        document.addEventListener('touchstart', handleTouchStart, {
            passive: false
        })
        document.addEventListener('touchmove', handleTouchMove, {
            passive: false
        })

        return () => {
            resetStyles()
            document.removeEventListener('touchstart', handleTouchStart)
            document.removeEventListener('touchmove', handleTouchMove)
        }
    }, [isOpen])
}
