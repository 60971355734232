import { formatDistanceToNow } from 'date-fns'
import differenceInDays from 'date-fns/differenceInDays'
import { truncate } from 'lodash'
import BookmarkIcon from 'share-svgs/svgs/bookmark.svg'
import BrandDefaultIcon from 'share-svgs/svgs/brand-default.svg'
import HighlightIcon from 'share-svgs/svgs/note-filled.svg'
import { Avatar, Box, Flex, HStack, IconButton, Text } from 'ui'
import { Thumbnails } from 'ui/types'
import fetcher from '../../utils/fetcher'
import SessionCardPlayer from './SessionCardPlayer'

type Props = {
    id: string
    index?: number
    type: 'audio' | 'video'
    name: string
    thumbnails?: Thumbnails
    muxPlaybackId: string
    muxTokens: { playback: string; storyboard: string; thumbnail: string }
    duration: number
    description?: string
    avatar?: {
        image?: Thumbnails
        name: string
    }
    bookmarked?: boolean
    hasHighlights?: boolean
    publishedAt: string
    recordedAt?: string
    enableBookmark?: boolean
    updateSessions?(): void
    handleRedirect(): void
}

export const SessionCard = ({
    avatar,
    bookmarked,
    id,
    index,
    type,
    name,
    description,
    muxPlaybackId,
    muxTokens,
    duration,
    hasHighlights,
    publishedAt,
    recordedAt,
    thumbnails,
    handleRedirect,
    updateSessions,
    enableBookmark
}: Props) => {
    const daysDifference = differenceInDays(new Date(), new Date(publishedAt))

    const handleBookmarking = async () => {
        try {
            if (!bookmarked) {
                await fetcher.post(`/sessions/${id}/bookmarks`)
            } else {
                await fetcher.delete(`/sessions/${id}/bookmarks`)
            }
            updateSessions && updateSessions()
        } catch (e: unknown) {
            alert(e)
        }
    }

    return (
        <Box
            key={id}
            pointer
            gap="2"
            onClick={handleRedirect}
            data-testid={`session-card-${index}`}
        >
            <SessionCardPlayer
                id={id}
                type={type}
                name={name}
                muxPlaybackId={muxPlaybackId}
                muxTokens={muxTokens}
                duration={duration}
                recordedAt={recordedAt}
                thumbnails={thumbnails}
            />
            <Flex
                justifyContent="flexStart"
                alignItems="flexStart"
                gap="2"
                css={{
                    '& .default-icon': {
                        width: '35rem'
                    }
                }}
            >
                {avatar && avatar.image ? (
                    <Avatar
                        src={avatar.image['jpg-desktop']}
                        width={35}
                        height={35}
                        alt={name}
                    />
                ) : null}
                {avatar && !avatar.image ? (
                    <BrandDefaultIcon className="default-icon" />
                ) : null}
                <Box>
                    <Flex justifyContent="spaceBetween" alignItems="flexStart">
                        <Text
                            weight="lightBold"
                            size="2"
                            css={{
                                wordBreak: 'break-word',
                                overflowWrap: 'break-word',
                                lineHeight: '16rem',
                                '&:svg': { '&:path': { fill: '$sky' } }
                            }}
                            textTransform="upper"
                        >
                            {name}
                        </Text>
                        <HStack
                            spacing={0}
                            justifyContent={'flexEnd'}
                            widthAuto
                            css={{ pl: '10rem' }}
                        >
                            {hasHighlights ? (
                                <IconButton
                                    aria-label={'highlighted'}
                                    css={{
                                        pointerEvents: 'none',
                                        $$iconWidth: '25rem',
                                        $$iconHeight: '25rem',
                                        '@lg': {
                                            $$iconWidth: '20rem',
                                            $$iconHeight: '18rem'
                                        }
                                    }}
                                >
                                    <HighlightIcon />
                                </IconButton>
                            ) : null}
                            {enableBookmark ? (
                                <IconButton
                                    aria-label={'bookmark'}
                                    className={bookmarked ? 'bookmarked' : ''}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        handleBookmarking()
                                    }}
                                    css={{
                                        $$iconWidth: '25rem',
                                        $$iconHeight: '28rem',
                                        '@lg': {
                                            $$iconWidth: '20rem',
                                            $$iconHeight: '18rem'
                                        },
                                        '&.bookmarked': {
                                            '& svg path': { fill: '$deep_aqua' }
                                        }
                                    }}
                                >
                                    <BookmarkIcon />
                                </IconButton>
                            ) : null}
                        </HStack>
                    </Flex>
                    {description ? (
                        <Text
                            size="1"
                            weight="medium"
                            css={{
                                wordBreak: 'break-word',
                                overflowWrap: 'break-word'
                            }}
                        >
                            {truncate(description, { length: 175 })}
                        </Text>
                    ) : null}
                    {daysDifference < 15 ? (
                        <Text size="2" weight="medium" color="gray">
                            {`Uploaded ` +
                                formatDistanceToNow(Date.parse(publishedAt), {
                                    addSuffix: true
                                })}
                        </Text>
                    ) : null}
                </Box>
            </Flex>
        </Box>
    )
}
