import { styled } from '../../theme/stitches.config'

export const IconButton = styled('button', {
    $$iconHeight: '25rem',
    $$iconWidth: 'auto',
    $$disabledColor: '$colors$tuna',
    $$fontSize: '$fontSizes$2',
    $$svgMr: '0',
    color: '$$primaryColor',
    fontSize: '$$fontSize',
    fontWeight: '$bold',
    display: 'flex',
    lineHeight: '1',
    alignItems: 'center',
    textTransform: 'uppercase',
    '& svg': {
        width: '$$iconWidth',
        height: '$$iconHeight',
        mr: '$$svgMr'
    },
    '&[disabled]': {
        'svg path': {
            fill: '$$disabledColor'
        },
        color: '$$disabledColor',
        pointerEvents: 'none'
    },
    variants: {
        variant: {
            square: {
                height: '40rem',
                width: '40rem',
                borderRadius: '6rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '$dark_lighter'
            }
        },
        primaryColor: {
            white: {
                '& svg path': {
                    fill: '$ivory'
                }
            },
            ivory: {
                '& svg path': {
                    fill: '$ivory'
                }
            },
            navy: {
                '& svg path': {
                    fill: '$navy'
                }
            },
            dark: {
                '& svg path': {
                    fill: '$dark'
                }
            },
            nobel: {
                '& svg path': {
                    fill: '$nobel'
                }
            },
            orange: {
                '& svg path': {
                    fill: '$orange'
                }
            },
            burnt: {
                '& svg path': {
                    fill: '$burnt'
                }
            },
            cyan: {
                '& svg path': {
                    fill: '$cyan'
                }
            },
            gray: {
                '& svg path': {
                    fill: '$gray'
                }
            },
            sky: {
                '& svg path': {
                    fill: '$sky'
                }
            },
            deep_aqua: {
                '& svg path': {
                    fill: '$deep_aqua'
                }
            }
        },
        noMargin: {
            true: {
                '& svg': {
                    mr: '0'
                }
            }
        }
    }
})
