import * as TabsPrimitive from '@radix-ui/react-tabs'
import { styled } from '../../theme/stitches.config'

export const Tabs = styled(TabsPrimitive.Root, {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
})

export const TabsList = styled(TabsPrimitive.List, {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '1rem solid $squid'
})

export const TabsTrigger = styled(TabsPrimitive.Trigger, {
    padding: '5rem 35rem',
    fontSize: '$3',
    fontWeight: '$bold',
    letterSpacing: '$1',
    color: '$ivory',
    textTransform: 'uppercase',
    userSelect: 'none',
    '&:hover': {
        color: '$sky'
    },
    '&[data-state="active"]': {
        color: '$sky',
        borderBottom: '2rem solid $sky'
    }
})

export const TabsContent = styled(TabsPrimitive.Content, {
    flexGrow: 1,
    padding: '25rem 0',
    outline: 'none'
})
