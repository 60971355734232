import { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'
import LoaderIcon from 'share-svgs/svgs/loader.svg'
import WarningIcon from 'share-svgs/svgs/warning.svg'
import useSWR from 'swr'
import {
    Banner,
    Box,
    Button,
    Flex,
    Heading,
    HStack,
    LinkButton,
    Text
} from 'ui'
import fetcher from 'ui/utils/fetcher'

type Props = {
    twoFactorEnabled?: boolean
    setTwoFactorDone?: (value: boolean) => void
    setQrCodeReady?: (value: boolean) => void
}

export const TwoFactor = ({
    twoFactorEnabled,
    setTwoFactorDone,
    setQrCodeReady
}: Props) => {
    const [verifyCode, setVerifyCode] = useState('')
    const [isVerifying, setIsVerifying] = useState(false)
    const [twoFactorOn, setTwoFactorOn] = useState(twoFactorEnabled)

    const { data: twoFactorQR, error } = useSWR(
        '/users/two-factor-auth/qr',
        fetcher.get
    )

    const enableTwoFactor = async () => {
        try {
            setIsVerifying(true)
            await fetcher.post('/users/two-factor-auth/verify', {
                two_factor_code: verifyCode
            })
            setTwoFactorDone && setTwoFactorDone(true)
            setTwoFactorOn(true)
            setVerifyCode('')
        } catch (error) {
            alert(error)
        } finally {
            setIsVerifying(false)
        }
    }

    const disableTwoFactor = async () => {
        try {
            await fetcher.post('users/two-factor-auth/disable')
            setTwoFactorOn(false)
        } catch (error) {
            alert(error)
        }
    }

    const downloadRecoveryCode = async () => {
        try {
            const codes = await fetcher.put(
                '/users/two-factor-auth/recovery-codes'
            )

            const blob = new Blob([codes.join('\n')], { type: 'text/csv' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'recovery_codes.csv'

            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
        } catch (error) {
            alert(error)
        }
    }

    useEffect(() => {
        if (twoFactorQR) {
            setQrCodeReady && setQrCodeReady(true)
        }
    }, [twoFactorQR])

    useEffect(() => {
        if (verifyCode.length === 6) {
            enableTwoFactor()
        }
    }, [verifyCode])

    return (
        <>
            {(twoFactorQR || twoFactorEnabled || twoFactorOn) && (
                <Flex direction={'col'} alignItems={'flexStart'}>
                    <Heading
                        as="h3"
                        size="3"
                        textTransform="upper"
                        letterSpacing
                        css={{ mb: '10rem' }}
                    >
                        Two-Factor Authentication
                    </Heading>
                    <Text size="3" weight={'extraLight'}>
                        Two-factor authentication is recommended for enhanced
                        security measures.
                    </Text>
                    {twoFactorQR && !twoFactorOn && (
                        <>
                            <Banner
                                variant="info"
                                spacing="5"
                                description={
                                    <HStack
                                        spacing={'1'}
                                        css={{
                                            svg: {
                                                width: '40'
                                            },
                                            'svg path': {
                                                fill: '$ivory'
                                            }
                                        }}
                                    >
                                        <WarningIcon
                                            style={{ height: '20rem' }}
                                        />
                                        <Text textTransform={'upper'}>
                                            use a mobile authentication app of
                                            your choice to get a verification
                                            code to enter every time you log
                                            into statemints.
                                        </Text>
                                    </HStack>
                                }
                            />
                            <Text css={{ mb: '10rem' }} weight={'extraLight'}>
                                Please verify by scanning the following QR code
                                in your authentication app and entering the 6
                                digit code.
                            </Text>
                        </>
                    )}
                    {twoFactorOn && (
                        <Banner
                            variant="info"
                            spacing="5"
                            description={
                                <HStack
                                    spacing={'1'}
                                    css={{
                                        'svg path': {
                                            fill: '$ivory'
                                        }
                                    }}
                                >
                                    <WarningIcon style={{ height: '20rem' }} />
                                    <Text textTransform={'upper'}>
                                        to change authentication app of your
                                        choice. disable 2fa and rescan the qr
                                        code.
                                    </Text>
                                </HStack>
                            }
                        />
                    )}
                </Flex>
            )}
            {twoFactorQR && !twoFactorOn && (
                <Flex
                    direction={'col'}
                    gap="5"
                    justifyContent={'flexStart'}
                    alignItems={'flexStart'}
                >
                    <img
                        src={`data:image/png;base64,${twoFactorQR}`}
                        style={{ width: '150rem' }}
                        alt="two factor qr code"
                    />
                    <OtpInput
                        value={verifyCode}
                        onChange={setVerifyCode}
                        numInputs={6}
                        containerStyle={{
                            display: 'flex',
                            gap: '10rem'
                        }}
                        inputStyle={{
                            backgroundColor: '#3C4347',
                            width: '30rem',
                            height: '40rem',
                            border: '1px solid #999999',
                            borderRadius: '6rem',
                            color: 'white'
                        }}
                        renderInput={(props: any) => <input {...props} />}
                    />

                    {isVerifying ? (
                        <Button rotateSvg={true} disabled>
                            <LoaderIcon />
                            verifying...
                        </Button>
                    ) : (
                        <Button
                            onClick={enableTwoFactor}
                            disabled={verifyCode.length < 6}
                        >
                            Verify
                        </Button>
                    )}
                </Flex>
            )}
            {twoFactorOn && (
                <Box alignItems={'flexStart'} gap="8">
                    <Button color="secondary" onClick={disableTwoFactor}>
                        disable authentication
                    </Button>
                    <LinkButton
                        type="button"
                        size="sm"
                        onClick={downloadRecoveryCode}
                    >
                        download recovery code
                    </LinkButton>
                </Box>
            )}
            {error && <Text>{error}</Text>}
        </>
    )
}
