import { motion } from 'motion/react'

interface HighlightedTextProps {
    children: React.ReactNode
    backgroundColor?: string
    textColor?: string
    delay?: number
}

export const HighlightedText = ({
    children,
    backgroundColor = '#0097A0',
    delay = 1
}: HighlightedTextProps) => {
    return (
        <motion.span
            initial={{ backgroundSize: '0% 100%' }}
            whileInView={{ backgroundSize: '100% 100%' }}
            viewport={{ once: true, amount: 1 }}
            transition={{ duration: 1, delay }}
            style={{
                position: 'relative',
                borderRadius: '8px',
                right: '4px',
                padding: '0 4px 4px 4px',
                fontWeight: '800',
                backgroundImage: `linear-gradient(${backgroundColor}, ${backgroundColor})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'left center',
                backgroundSize: '0% 100%',
                letterSpacing: '-2px',
                boxDecorationBreak: 'clone',
                WebkitBoxDecorationBreak: 'clone'
            }}
        >
            {children}
        </motion.span>
    )
}
