import { useCallback, useRef, useState } from 'react'
import {
    RefinementList,
    useRefinementList,
    UseRefinementListProps,
    useStats
} from 'react-instantsearch'
import SearchIcon from 'share-svgs/svgs/search.svg'
import { Badge, Box, Divider, Flex, Heading, HStack, Text, VStack } from 'ui'
import { styled } from 'ui/theme/stitches.config'
import { IconButton } from '../Button'

const monthOrder: Record<string, number> = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12
}

export default function RefinementListAside() {
    const transformItems = useCallback(
        (items: any) =>
            items.map((item: any) => ({
                ...item,
                label: item.label === '1' ? 'Private' : 'Public'
            })),
        []
    )

    const sortMonths = useCallback((a: any, b: any) => {
        return monthOrder[a.name] - monthOrder[b.name]
    }, [])

    return (
        <Box
            alignItems={'flexStart'}
            justifyContent={'flexStart'}
            css={{
                '@lg': {
                    padding: '15rem'
                },
                '.ais-RefinementList': {
                    width: '100%'
                },
                '.ais-RefinementList-labelText': {
                    marginLeft: '5rem'
                },
                '.ais-RefinementList-count': {
                    padding: '$1 $2',
                    marginLeft: '$2',
                    borderRadius: '$pill',
                    backgroundColor: '$deep_aqua_30',
                    fontWeight: 'bold',
                    fontSize: '$1'
                },
                '.ais-RefinementList-label': {
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '8px'
                },
                '.ais-RefinementList-showMore': {
                    color: '$deep_aqua'
                },
                '.ais-RefinementList-showMore--disabled': {
                    display: 'none'
                },
                '.ais-RefinementList-checkbox': {
                    WebkitAppearance: 'none',
                    marginRight: '$2',
                    appearance: 'none',
                    width: '20rem',
                    height: '20rem',
                    backgroundColor: '$raven_black',
                    border: '1rem solid $deep_aqua',
                    borderRadius: '4rem',
                    outline: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    cursor: 'pointer',
                    transition: '.3s',
                    '&:checked': {
                        backgroundColor: '$deep_aqua',
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20rem',
                            height: '20rem',
                            backgroundImage:
                                "url(\"data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ctitle%3Echeck icon%3C/title%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m14.284 18.816 6.772-6.773 1.414 1.414-8.186 8.187-4.754-4.753 1.415-1.414 3.338 3.339Z' fill='%23FFFFFF' /%3E%3C/svg%3E\")",
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                        }
                    }
                }
            }}
        >
            <CustomRefinementList attribute="brand.name" title="brand" />

            <CustomRefinementList
                title="folder"
                attribute="category.folder.name"
                showMore={true}
                limit={5}
            />

            <CustomRefinementList
                title="category"
                attribute="category.name"
                showMore={true}
                limit={5}
            />

            <CustomRefinementList
                attribute="month"
                title="month"
                limit={5}
                showMore={true}
                sortBy={sortMonths}
            />

            <CustomRefinementList
                title="year"
                attribute="year"
                sortBy={['name:asc']}
                showMore={true}
                limit={5}
            />

            <CustomRefinementList
                title="speaker"
                attribute="t.speaker"
                limit={5}
            />

            {process.env.APP_NAME !== 'statemints' && (
                <Box
                    bRadius={'3'}
                    css={{
                        padding: '12rem $4 4rem',
                        background: 'rgba(0, 0, 0, 0.25)'
                    }}
                >
                    <Heading
                        as="h3"
                        size="3"
                        textTransform={'upper'}
                        css={{
                            marginBottom: '8rem',
                            letterSpacing: '1rem'
                        }}
                    >
                        visibility
                    </Heading>
                    <RefinementList
                        attribute="private"
                        searchable={false}
                        transformItems={transformItems}
                    />
                </Box>
            )}
        </Box>
    )
}

function CustomRefinementList(
    props: UseRefinementListProps & { title: string }
) {
    const inputRef = useRef<HTMLInputElement>(null)

    const [showSearch, setShowSearch] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const {
        items,
        refine,
        searchForItems,
        canToggleShowMore,
        isShowingMore,
        toggleShowMore
    } = useRefinementList(props)
    const { nbHits } = useStats()

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value
        searchForItems(value)
        setInputValue(value)
    }

    const handleToggleSearchWithinRefinement = () => {
        setShowSearch(true)
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }

    const handleOnBlur = () => {
        setShowSearch(false)
        setInputValue('')
        searchForItems('')
    }

    return (
        <>
            <Flex css={{ marginBottom: '$1' }}>
                {showSearch ? (
                    <Box
                        direction={'row'}
                        alignItems={'center'}
                        css={{
                            padding: '3rem 8rem',
                            borderRadius: '$pill',
                            background: '$tuna',
                            '&:focus-within': {
                                boxShadow: '0 0 0 1rem #0097A0'
                            }
                        }}
                    >
                        <StyledSearchInput
                            ref={inputRef}
                            type="search"
                            value={inputValue}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck={false}
                            maxLength={512}
                            onChange={handleOnChange}
                            onBlur={handleOnBlur}
                            autoFocus
                        />

                        <SearchIcon
                            style={{
                                height: '13rem'
                            }}
                        />
                    </Box>
                ) : (
                    <HStack
                        spacing={'2'}
                        css={{
                            height: '24rem'
                        }}
                    >
                        <Heading as="h3" size="3" textTransform={'capitalize'}>
                            {props.title}
                        </Heading>
                        <IconButton
                            css={{ $$iconHeight: '15rem' }}
                            onClick={handleToggleSearchWithinRefinement}
                        >
                            <SearchIcon />
                        </IconButton>
                    </HStack>
                )}
            </Flex>
            <VStack as={'ol'} spacing={'1'} alignItems={'flexStart'}>
                {items.map((item) => (
                    <li key={item.label}>
                        <Flex
                            as={'label'}
                            css={{
                                cursor: 'pointer'
                            }}
                        >
                            <StyledCheckBox
                                type="checkbox"
                                checked={item.isRefined}
                                onChange={() => refine(item.value)}
                            />
                            <Text
                                css={{
                                    maxWidth: '200px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {item.label}
                            </Text>
                            <Badge text={`${item.count}`} />
                        </Flex>
                    </li>
                ))}
            </VStack>

            {!items.length && nbHits > 1 && (
                <Text as="p"> There&apos;s no result for {inputValue}</Text>
            )}

            {!items.length && nbHits === 0 && <Text as="p">No Result</Text>}

            {canToggleShowMore ? (
                <StyledShowMoreButton onClick={toggleShowMore}>
                    {isShowingMore ? 'Show less' : 'Show more'}
                </StyledShowMoreButton>
            ) : null}

            <Divider
                spacing={'md'}
                css={{
                    $$borderColor: '$colors$gray'
                }}
            />
        </>
    )
}

const StyledShowMoreButton = styled('button', {
    marginTop: '$2',
    color: '$deep_aqua'
})

const StyledSearchInput = styled('input', {
    border: 'none',
    outline: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '$ivory',
    paddingRight: '20rem',
    fontSize: '$3',
    display: 'flex',
    flex: '1 1 0%',
    background: '$tuna',
    '&::placeholder': {
        color: '$gray'
    },
    '&::-webkit-search-cancel-button': {
        '-webkit-appearance': 'none',
        appearance: 'none',
        display: 'none'
    },
    /* Hide clear button in Internet Explorer */
    '&::-ms-clear': {
        display: 'none'
    }
})

const StyledCheckBox = styled('input', {
    WebkitAppearance: 'none',
    marginRight: '$2',
    appearance: 'none',
    width: '20rem',
    height: '20rem',
    backgroundColor: '$raven_black',
    border: '1rem solid $deep_aqua',
    borderRadius: '4rem',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    cursor: 'pointer',
    transition: '.3s',
    '&:checked': {
        backgroundColor: '$deep_aqua',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem',
            height: '20rem',
            backgroundImage:
                "url(\"data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ctitle%3Echeck icon%3C/title%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m14.284 18.816 6.772-6.773 1.414 1.414-8.186 8.187-4.754-4.753 1.415-1.414 3.338 3.339Z' fill='%23FFFFFF' /%3E%3C/svg%3E\")",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
        }
    }
})
