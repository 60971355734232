import { yupResolver } from '@hookform/resolvers/yup'
import { csrf } from 'auth'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { MessageDialog, MessageDialogType, Text } from 'ui'
import { styled } from 'ui/theme/stitches.config'
import fetcher from 'ui/utils/fetcher'
import * as yup from 'yup'
import IndustrySelect from './IndustrySelect'

const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

const formSchema = yup.object().shape({
    firstName: yup
        .string()
        .required('Required')
        .max(50, 'Must be 50 characters or less'),
    lastName: yup
        .string()
        .required('Required')
        .max(50, 'Must be 50 characters or less'),
    email: yup
        .string()
        .email('Invalid email')
        .required('Required')
        .max(255, 'Must be 255 characters or less'),
    phoneNumber: yup
        .string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('Required')
        .max(30, 'Must be 30 characters or less'),
    title: yup
        .string()
        .required('Required')
        .max(255, 'Must be 255 characters or less'),
    industry: yup.string().required('Required'),
    company: yup
        .string()
        .required('Required')
        .max(255, 'Must be 255 characters or less'),
    acceptTermsAndPrivacy: yup
        .boolean()
        .oneOf([true], 'Field must be checked')
        .required('Required'),
    agreedToPromotional: yup.boolean().default(false)
})

type FormValues = yup.InferType<typeof formSchema>

export const LearnMoreForm = () => {
    const router = useRouter()

    const [messageDialog, setMessageDialog] =
        useState<MessageDialogType>(undefined)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleCloseMessageDialog = () => {
        setMessageDialog(undefined)
    }

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isValid }
    } = useForm<FormValues>({
        mode: 'onChange',
        resolver: yupResolver(formSchema)
    })

    const onSubmit = async (data: FormValues) => {
        setIsSubmitting(true)
        try {
            await csrf()

            await fetcher.post(`/email-inquiries`, {
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                phone: data.phoneNumber,
                title: data.title,
                industry: data.industry,
                company: data.company,
                agrees_to_receive_promotional_material:
                    data.agreedToPromotional,
                accepts_and_agrees_on_terms_and_conditions_and_privacy_policy:
                    data.acceptTermsAndPrivacy
            })

            await router.push('/get-started/success')
        } catch (error: any) {
            if (error.isAxiosError) {
                setMessageDialog({
                    title: `Error`,
                    description: error.response.data.message
                })
            } else {
                setMessageDialog({
                    title: `Error`,
                    description: 'An unexpected error occurred.'
                })
            }
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <>
            <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                <FullWidthWrapper>
                    <Text
                        textTransform="upper"
                        textAlign="center"
                        css={{
                            zIndex: 10,
                            fontSize: '35px',
                            fontWeight: '800',
                            marginBottom: '50px',
                            '@md': {
                                fontSize: '55px'
                            }
                        }}
                    >
                        learn more
                    </Text>
                </FullWidthWrapper>

                <InputWrapper css={{ zIndex: 10, '@md': { mr: '20px' } }}>
                    <Label>First Name</Label>
                    <Input type="text" {...register('firstName')} />
                    {errors.firstName && (
                        <ErrorMessage>{errors.firstName.message}</ErrorMessage>
                    )}
                </InputWrapper>

                <InputWrapper css={{ zIndex: 10, '@md': { mr: '20px' } }}>
                    <Label>Last Name</Label>
                    <Input type="text" {...register('lastName')} />
                    {errors.lastName && (
                        <ErrorMessage>{errors.lastName.message}</ErrorMessage>
                    )}
                </InputWrapper>

                <InputWrapper css={{ zIndex: 10, '@md': { mr: '20px' } }}>
                    <Label>Email</Label>
                    <Input type="email" {...register('email')} />
                    {errors.email && (
                        <ErrorMessage>{errors.email.message}</ErrorMessage>
                    )}
                </InputWrapper>

                <InputWrapper css={{ zIndex: 10, '@md': { mr: '20px' } }}>
                    <Label>Phone Number</Label>
                    <Input type="tel" {...register('phoneNumber')} />
                    {errors.phoneNumber && (
                        <ErrorMessage>
                            {errors.phoneNumber.message}
                        </ErrorMessage>
                    )}
                </InputWrapper>

                <InputWrapper css={{ zIndex: 10, '@md': { mr: '20px' } }}>
                    <Label>Title</Label>
                    <Input type="text" {...register('title')} />
                    {errors.title && (
                        <ErrorMessage>{errors.title.message}</ErrorMessage>
                    )}
                </InputWrapper>

                <Controller
                    name="industry"
                    control={control}
                    render={({ field }) => (
                        <InputWrapper
                            css={{ zIndex: 10, '@md': { mr: '20px' } }}
                        >
                            <Label>Industry</Label>
                            <IndustrySelect
                                onSelectIndustry={(value) => {
                                    field.onChange(value)
                                }}
                            />
                            {errors.industry && (
                                <ErrorMessage>
                                    {errors.industry.message}
                                </ErrorMessage>
                            )}
                        </InputWrapper>
                    )}
                />

                <FullWidthWrapper
                    css={{
                        zIndex: 10,
                        pb: '25px',
                        mt: '$1',
                        '@md': { marginTop: '0' }
                    }}
                >
                    <Label>Company</Label>
                    <Input type="text" {...register('company')} />
                    {errors.company && (
                        <ErrorMessage>{errors.company.message}</ErrorMessage>
                    )}
                </FullWidthWrapper>

                <CheckboxWrapper>
                    <Checkbox
                        type="checkbox"
                        {...register('agreedToPromotional')}
                    />
                    <span>
                        By checking this box, I agree to receive information and
                        promotional material about Jogg, co.
                    </span>
                </CheckboxWrapper>

                <div></div>

                <CheckboxWrapper>
                    <Checkbox
                        type="checkbox"
                        {...register('acceptTermsAndPrivacy')}
                    />
                    <span>
                        By submitting this form, you accept and agree to the{' '}
                        <a
                            href="/terms-of-use"
                            style={{
                                textDecoration: 'underline',
                                fontWeight: 'bold'
                            }}
                        >
                            terms of use
                        </a>{' '}
                        and the{' '}
                        <a
                            href="/privacy-policy"
                            style={{
                                textDecoration: 'underline',
                                fontWeight: 'bold'
                            }}
                        >
                            privacy policy
                        </a>
                        .
                    </span>
                </CheckboxWrapper>

                <FullWidthWrapper
                    style={{ textAlign: 'center', marginTop: '20px' }}
                >
                    <SubmitButton
                        type="submit"
                        disabled={!isValid || isSubmitting}
                    >
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </SubmitButton>
                </FullWidthWrapper>
            </FormWrapper>
            <MessageDialog
                messageDialog={messageDialog}
                handleCloseDialog={handleCloseMessageDialog}
            />
        </>
    )
}

const FormWrapper = styled('form', {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '800px',
    width: '100%',
    margin: '0 auto',

    '@md': {
        display: 'grid',
        padding: '50px',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center'
    }
})

const FullWidthWrapper = styled('div', {
    gridColumn: 'span 2',
    zIndex: 10
})

const InputWrapper = styled('div', {
    pb: '25px'
})

const Label = styled('label', {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'white',
    marginBottom: '6px',
    display: 'block'
})

const Input = styled('input', {
    width: '100%',
    padding: '14px',
    borderRadius: '30px',
    border: 'none',
    outline: 'none',
    backgroundColor: '$light_gray',
    fontSize: '14px',
    fontWeight: '500'
})

const CheckboxWrapper = styled('div', {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px',
    fontSize: '12px',
    lineHeight: '1.4',
    pb: '20px',
    gridColumn: 'span 2'
})

const Checkbox = styled('input', {
    appearance: 'none',
    width: '20px',
    height: '20px',
    borderRadius: '4px',
    border: '1px solid $gray',
    backgroundColor: '$light_gray',
    cursor: 'pointer',
    position: 'relative',
    transition: 'background 0.3s, border 0.3s',

    '&:checked': {
        backgroundColor: '$deep_aqua',
        border: '1px solid $gray'
    },

    '&:checked::after': {
        content: '""',
        position: 'absolute',
        top: '40%',
        left: '50%',
        width: '12px',
        height: '8px',
        border: '2px solid white',
        borderTop: 'none',
        borderRight: 'none',
        transform: 'translate(-50%, -50%) rotate(-45deg)'
    }
})

const SubmitButton = styled('button', {
    backgroundColor: '$magenta_300',
    letterSpacing: '3rem',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '12px',
    borderRadius: '30px',
    border: 'none',
    cursor: 'pointer',
    textTransform: 'uppercase',
    gridColumn: 'span 2',
    width: '140px',
    '&:disabled': {
        backgroundColor: '$gray',
        cursor: 'not-allowed'
    }
})

const ErrorMessage = styled('p', {
    color: 'red',
    fontSize: '12px',
    position: 'absolute',
    marginTop: '1px'
})
