import { styled } from '../../theme/stitches.config'

export const Grid = styled('div', {
    width: '100%',
    boxSizing: 'border-box',
    display: 'grid',

    variants: {
        align: {
            start: {
                alignItems: 'start'
            },
            center: {
                alignItems: 'center'
            },
            end: {
                alignItems: 'end'
            },
            stretch: {
                alignItems: 'stretch'
            },
            baseline: {
                alignItems: 'baseline'
            }
        },
        justify: {
            start: {
                justifyContent: 'start'
            },
            center: {
                justifyContent: 'center'
            },
            end: {
                justifyContent: 'end'
            },
            between: {
                justifyContent: 'space-between'
            }
        },
        flow: {
            row: {
                gridAutoFlow: 'row'
            },
            column: {
                gridAutoFlow: 'column'
            },
            dense: {
                gridAutoFlow: 'dense'
            },
            rowDense: {
                gridAutoFlow: 'row dense'
            },
            columnDense: {
                gridAutoFlow: 'column dense'
            }
        },
        columns: {
            1: {
                gridTemplateColumns: 'repeat(1, 1fr)'
            },
            2: {
                gridTemplateColumns: 'repeat(2, 1fr)'
            },
            3: {
                gridTemplateColumns: 'repeat(3, 1fr)'
            },
            4: {
                gridTemplateColumns: 'repeat(4, 1fr)'
            }
        },
        rows: {
            1: {
                gridTemplateRows: 'repeat(1, 1fr)'
            },
            2: {
                gridTemplateRows: 'repeat(2, 1fr)'
            },
            3: {
                gridTemplateRows: 'repeat(3, 1fr)'
            },
            4: {
                gridTemplateRows: 'repeat(4, 1fr)'
            },
            5: {
                gridTemplateRows: 'repeat(5, 1fr)'
            },
            6: {
                gridTemplateRows: 'repeat(6, 1fr)'
            },
            7: {
                gridTemplateRows: 'repeat(7, 1fr)'
            },
            8: {
                gridTemplateRows: 'repeat(8, 1fr)'
            },
            9: {
                gridTemplateRows: 'repeat(9, 1fr)'
            }
        },
        gap: {
            1: {
                gap: '$1'
            },
            2: {
                gap: '$2'
            },
            3: {
                gap: '$3'
            },
            4: {
                gap: '$4'
            },
            5: {
                gap: '$5'
            },
            6: {
                gap: '$6'
            },
            7: {
                gap: '$7'
            },
            8: {
                gap: '$8'
            },
            9: {
                gap: '$9'
            }
        },
        gapX: {
            1: {
                columnGap: '$1'
            },
            2: {
                columnGap: '$2'
            },
            3: {
                columnGap: '$3'
            },
            4: {
                columnGap: '$4'
            },
            5: {
                columnGap: '$5'
            },
            6: {
                columnGap: '$6'
            },
            7: {
                columnGap: '$7'
            },
            8: {
                columnGap: '$8'
            },
            9: {
                columnGap: '$9'
            }
        },
        gapY: {
            1: {
                rowGap: '$1'
            },
            2: {
                rowGap: '$2'
            },
            3: {
                rowGap: '$3'
            },
            4: {
                rowGap: '$4'
            },
            5: {
                rowGap: '$5'
            },
            6: {
                rowGap: '$6'
            },
            7: {
                rowGap: '$7'
            },
            8: {
                rowGap: '$8'
            },
            9: {
                rowGap: '$9'
            }
        },
        bg: {
            dark: {
                backgroundColor: '$dark'
            },
            raven_black: {
                backgroundColor: '$raven_black'
            }
        },
        bRadius: {
            1: {
                borderRadius: '$1'
            }
        }
    }
})
