import { Heading, Text, VStack } from 'ui'

type Props = {
    heading: string
    title: string
    description: string
    upper?: boolean
}

export const HeadingHeader = ({
    title,
    heading,
    description,
    upper
}: Props) => (
    <VStack
        as="section"
        alignItems={'center'}
        justifyContent={'center'}
        css={{
            background: '$cloudy_night',
            minHeight: '120rem',
            '@md': {
                minHeight: '150rem'
            }
        }}
    >
        <Text
            color={'cove'}
            textTransform={upper ? 'upper' : 'capitalize'}
            size={'1'}
        >
            {title}
        </Text>
        <Heading color={'white'}>{heading}</Heading>
        <Text color={'cove'}>{description}</Text>
    </VStack>
)
