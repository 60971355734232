import { Box, Text } from 'ui'

export const Badge = ({
    text,
    textSize = '1'
}: {
    text: string
    textSize?: '1' | '2' | '3' | '4' | '5'
}) => {
    return (
        <Box
            bRadius={'pill'}
            flexBasis={'content'}
            css={{
                padding: '$1 $2',
                marginLeft: '$2',
                backgroundColor: '$deep_aqua_30'
            }}
        >
            <Text
                size={textSize}
                weight={'bold'}
                textTransform={'upper'}
                css={{
                    lineHeight: '1'
                }}
            >
                {text}
            </Text>
        </Box>
    )
}
