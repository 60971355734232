import { useState } from 'react'
import { useEvent } from 'react-use'
import { Button, Container, Flex, Text, TextLink, VStack } from 'ui'
import { HeadingHeader } from './HeadingHeader'

export const PrivacyPolicy = () => {
    const [showButton, setShowButton] = useState(false)

    const onScrolling = () => {
        if (
            document.body.scrollTop > 400 ||
            document.documentElement.scrollTop > 400
        ) {
            setShowButton(true)
        } else {
            setShowButton(false)
        }
    }

    const scrollToTop = () => {
        document.body.scrollTop = 100
        document.documentElement.scrollTop = 100
    }

    useEvent('scroll', onScrolling, window)

    return (
        <Flex
            direction={'col'}
            justifyContent={'flexStart'}
            css={{
                flex: '1',
                background: '$ivory',
                color: '$dark_lighter'
            }}
        >
            <HeadingHeader
                title="Statemints"
                heading="Privacy Policy"
                description="Last Updated 10/10/2023"
            />

            <Container
                size="lg"
                css={{
                    p: '50rem $4',
                    '@md': {
                        p: '80rem $4'
                    }
                }}
            >
                <VStack alignItems={'flexStart'} spacing={5}>
                    <Text as="p">
                        This Privacy Policy describes the data protection
                        practices of Jogg Inc., d/b/a Statemints (“Statemints,”
                        “we,” “us,” or “our”). This Privacy Policy applies to
                        information that we collect and use about you when you
                        access or use the Statemints website, mobile
                        application, or other online or mobile service that
                        links to or otherwise presents this Privacy Policy to
                        you. We refer to these products and services
                        collectively as the “Services.”
                    </Text>
                    <Text as="p" weight={'bold'}>
                        PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND
                        HOW WE HANDLE YOUR INFORMATION. IF YOU DO NOT AGREE TO
                        THIS PRIVACY POLICY, PLEASE DO NOT USE THE SERVICES.s
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        Information We Collect
                    </Text>
                    <Text as="p">
                        We collect information about you through the means
                        discussed below. Please note that we need certain types
                        of information so that we can provide the Services to
                        you. If you do not provide us with such information, or
                        ask us to delete it, you may no longer be able to access
                        or use our Services.
                    </Text>

                    <Text as={'h3'} size={'4'} color={'error'}>
                        1. INFORMATION YOU PROVIDE TO US
                    </Text>
                    <Text as="p">
                        We collect a variety of information that you provide
                        directly to us. For example, we collect information from
                        you through:
                    </Text>
                    <Flex
                        as="ul"
                        direction={'col'}
                        css={{
                            listStyleType: 'disc',
                            fontSize: '$3',
                            fontWeight: '$normal',
                            lineHeight: 2,
                            alignItems: 'flex-start',
                            left: '30rem',
                            position: 'relative',
                            paddingRight: '30rem'
                        }}
                    >
                        <li>The Services you use or processing your orders </li>
                        <li>
                            Requests or questions you submit to us via online
                            forms, email, or otherwise{' '}
                        </li>
                        <li>
                            Your participation in sweepstakes, contests, or
                            surveys
                        </li>
                        <li>Any reviews that you submit about the Services</li>
                        <li>
                            Account registration and administration of your
                            account
                        </li>
                        <li>Uploads or posts to the Services</li>
                        <li>
                            Requests for customer support and technical
                            assistance
                        </li>
                    </Flex>
                    <Text as="h4" size={'4'} textTransform={'upper'}>
                        Information about you
                    </Text>
                    <Text as="p">
                        While parts of the Services may not require you to
                        provide any information that can directly identify you
                        by name (such as if you choose to browse the website
                        without logging in), the specific types of information
                        we collect will depend upon the Services you use, how
                        you use them, and the information you choose to provide.
                        The types of data we may collect directly from you
                        include the following:
                    </Text>
                    <Flex
                        as="ul"
                        direction={'col'}
                        css={{
                            listStyleType: 'disc',
                            fontSize: '$3',
                            fontWeight: '$normal',
                            lineHeight: 2,
                            alignItems: 'flex-start',
                            position: 'relative',
                            left: '30rem',
                            paddingRight: '30rem'
                        }}
                    >
                        <li>Email address</li>
                        <li>Name, if you choose to provide it</li>
                        <li>
                            Log-in credentials, if you create a Statemints
                            account
                        </li>
                        <li>
                            Billing information, if you choose to provide it,
                            such as shipping address of a gift card recipient,
                            credit or debit card number, verification number,
                            zip code, and expiration date
                        </li>
                        <li>Phone number, if you choose to provide it</li>
                        <li>
                            Information about purchases or other transactions
                            with us
                        </li>
                        <li>
                            Information about your customer service interactions
                            with us
                        </li>
                        <li>
                            Demographic information such as your gender or other
                            information you choose to provide as part of your
                            Statemints profile
                        </li>
                        <li>
                            User-generated content you provide to us, such as
                            when you create notes on the Services, or respond to
                            a survey request
                        </li>
                        <li>
                            Any other information you choose to directly provide
                            to us in connection with your use of the Services
                        </li>
                    </Flex>
                    <Text as="h4" size={'4'} textTransform={'upper'}>
                        Information about others
                    </Text>
                    <Text as="p">
                        If you request that your purchase be provided to someone
                        other than yourself (such as a gift recipient), we use
                        the information you provide about the other person to
                        fulfill the shipment.
                    </Text>
                    <Text as={'h3'} size={'4'} color={'error'}>
                        2. INFORMATION WE COLLECT THROUGH AUTOMATED MEANS{' '}
                    </Text>
                    <Text as="p">
                        When you use our Services, we collect certain
                        information as described in this Section. As discussed
                        further below, we and our service providers (which are
                        third party companies that work on our behalf) may use a
                        variety of technologies, including cookies and similar
                        tools, to assist in collecting this information.
                    </Text>
                    <Text as="h4" size={'4'} textTransform={'upper'}>
                        Websites
                    </Text>
                    <Text as="p">
                        When you use our website, we collect and analyze
                        information such as your IP address, browser types,
                        browser language, operating system, software and
                        hardware attributes (including device IDs) referring and
                        exit pages and URLs, the number of clicks, pages viewed
                        and the order of those pages, date and time of use,
                        content watched, total minutes watched, error logs, and
                        other similar information about how you use the website.{' '}
                    </Text>
                    <Text as="h4" size={'4'} textTransform={'upper'}>
                        Mobile Applications
                    </Text>
                    <Text as="p">
                        When you use a Statemints mobile application or software
                        (&quot;app&quot;), we automatically receive certain
                        information about the mobile phone, tablet, or computer
                        used to access the app, including device identifiers, IP
                        address, operating system, version, Internet service
                        provider, browser type, domain name and other similar
                        information, whether and when you update the app, date
                        and time of use, content watched, total minutes watched,
                        error logs, and other similar information about how you
                        use the app.{' '}
                    </Text>
                    <Text as="h4" size={'4'} textTransform={'upper'}>
                        Location Information
                    </Text>
                    <Text as="p">
                        When you use the Services, we and our service providers
                        may automatically collect general location information
                        (e.g., IP address, city/state and/or postal code
                        associated with an IP address, city/state) from your
                        computer or mobile device.{' '}
                    </Text>
                    <Text as={'h3'} size={'4'} color={'error'}>
                        3. INFORMATION WE COLLECT FROM OTHERS{' '}
                    </Text>
                    <Text as="p">
                        We may receive additional information such as
                        demographic and statistical information from third
                        parties, such as business partners, marketers,
                        researchers, analysts, and other parties that we may
                        attribute to you based on your assignment to certain
                        statistical groups. We use this information to
                        supplement the information that we collect directly from
                        you in order to derive your possible interests and to
                        provide more relevant experiences for you and improve
                        our products and analytics.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        How We Use Your Information{' '}
                    </Text>
                    <Text as="p">
                        We, or our service providers, use your information for
                        various purposes depending on the types of information
                        we have collected from and about you, in order to:
                    </Text>
                    <Flex
                        as="ul"
                        direction={'col'}
                        css={{
                            listStyleType: 'disc',
                            fontSize: '$3',
                            fontWeight: '$normal',
                            lineHeight: 2,
                            alignItems: 'flex-start',
                            position: 'relative',
                            left: '30rem',
                            paddingRight: '30rem'
                        }}
                    >
                        <li>
                            Provide the Services
                            <Flex
                                as="ul"
                                direction={'col'}
                                css={{
                                    listStyleType: 'disc',
                                    fontSize: '$3',
                                    fontWeight: '$normal',
                                    lineHeight: 2,
                                    alignItems: 'flex-start',
                                    position: 'relative',
                                    left: '25rem',
                                    paddingRight: '30rem'
                                }}
                            >
                                <li>
                                    Complete a purchase or provide the Services
                                    you have requested, including invoicing and
                                    accounting
                                </li>
                            </Flex>
                        </li>
                        <li>
                            Communicate with you and provide support{' '}
                            <Flex
                                as="ul"
                                direction={'col'}
                                css={{
                                    listStyleType: 'disc',
                                    fontSize: '$3',
                                    fontWeight: '$normal',
                                    lineHeight: 2,
                                    alignItems: 'flex-start',
                                    position: 'relative',
                                    left: '25rem',
                                    paddingRight: '30rem'
                                }}
                            >
                                <li>
                                    Respond to your request for information and
                                    provide you with more effective and
                                    efficient customer service
                                </li>
                                <li>
                                    Provide you with updates and information
                                    about content available on the Services
                                </li>
                            </Flex>
                        </li>
                        <li>
                            Marketing and content customization
                            <Flex
                                as="ul"
                                direction={'col'}
                                css={{
                                    listStyleType: 'disc',
                                    fontSize: '$3',
                                    fontWeight: '$normal',
                                    lineHeight: 2,
                                    alignItems: 'flex-start',
                                    position: 'relative',
                                    left: '25rem',
                                    paddingRight: '30rem'
                                }}
                            >
                                <li>
                                    Contact you by email, postal mail, or phone
                                    regarding Statemints and third-party
                                    products, services, surveys, research
                                    studies, promotions, special events and
                                    other subjects that we think may be of
                                    interest to you
                                </li>
                                <li>
                                    Customize the content you see on the
                                    Services{' '}
                                </li>
                            </Flex>
                        </li>
                        <li>
                            Business operations{' '}
                            <Flex
                                as="ul"
                                direction={'col'}
                                css={{
                                    listStyleType: 'disc',
                                    fontSize: '$3',
                                    fontWeight: '$normal',
                                    lineHeight: 2,
                                    alignItems: 'flex-start',
                                    position: 'relative',
                                    left: '25rem',
                                    paddingRight: '30rem'
                                }}
                            >
                                <li>
                                    Help us better understand your interests and
                                    needs, and improve the Services, including
                                    through research and reports, and test and
                                    create new products, features, and services
                                </li>
                                <li>
                                    Secure our websites and applications, and
                                    resolve app crashes and other issues being
                                    reported
                                </li>
                            </Flex>
                        </li>
                        <li>
                            Legal Purposes
                            <Flex
                                as="ul"
                                direction={'col'}
                                css={{
                                    listStyleType: 'disc',
                                    fontSize: '$3',
                                    fontWeight: '$normal',
                                    lineHeight: 2,
                                    alignItems: 'flex-start',
                                    position: 'relative',
                                    left: '25rem',
                                    paddingRight: '30rem'
                                }}
                            >
                                <li>
                                    Comply with any procedures, laws, and
                                    regulations which apply to us where it is
                                    necessary for our legitimate interests or
                                    the legitimate interests of others
                                </li>
                                <li>
                                    Establish, exercise, or defend our legal
                                    rights where it is necessary for our
                                    legitimate interests or the legitimate
                                    interests of others
                                </li>
                            </Flex>
                        </li>
                    </Flex>
                    <Text as="h4" size={'4'} textTransform={'upper'}>
                        Combined Information{' '}
                    </Text>
                    <Text as="p">
                        For the purposes discussed in this Privacy Policy, we
                        may combine the information that we collect through the
                        Services with information that we receive from other
                        sources, both online and offline, and use such combined
                        information in accordance with this Privacy Policy.{' '}
                    </Text>
                    <Text as="h4" size={'4'} textTransform={'upper'}>
                        Aggregate/Anonymous Data{' '}
                    </Text>
                    <Text as="p">
                        We may aggregate and/or anonymize any information
                        collected through the Services so that such information
                        can no longer be linked to you or your device. We may
                        use such information for any purpose, including without
                        limitation for research and marketing purposes, and may
                        also disclose such data to any third parties.{' '}
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        Cookies and Similar Technologies
                    </Text>
                    <Text as="p">
                        To collect the information in the “Information We
                        Collect Through Automated Means” section above, we and
                        our service providers use Internet server logs, cookies,
                        tracking pixels, and other similar tracking
                        technologies. We use these technologies in order to
                        offer you a more tailored experience in the future, by
                        understanding and remembering your particular browsing
                        preferences. Cookies are small text files that are
                        placed on your computer or mobile device when you visit
                        a site that enable us to: (i) recognize your computer;
                        (ii) store your preferences and settings; (iii)
                        understand the web pages of the Services you have
                        visited; (iv) enhance your user experience by delivering
                        and measuring the effectiveness of content tailored to
                        your interests; (v) perform searches and analytics; and
                        (vi) assist with security and administrative functions.
                    </Text>
                    <Text as="p">
                        As we adopt additional technologies, we may also gather
                        information through other methods. Please note that you
                        can change your settings to notify you when a cookie is
                        being set or updated or to block cookies altogether.
                        Please consult the “Help” section of your browser for
                        more information (e.g., Internet Explorer; Google
                        Chrome; Mozilla Firefox; or Apple Safari). You can also
                        manage the use of Flash technologies, including flash
                        cookies and local storage objects, with the Flash
                        management tools available at Adobe&apos;s website.
                        Please note that by blocking, disabling, or managing any
                        or all cookies, you may not have access to certain
                        features or offerings of the Services.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        Online Analytics{' '}
                    </Text>
                    <Text as={'h3'} size={'4'} color={'error'}>
                        1. ANALYTICS{' '}
                    </Text>
                    <Text as="p">
                        We may use third-party web analytics services on our
                        Services to collect and analyze usage information
                        through cookies and similar tools; engage in auditing,
                        research, or reporting; assist with fraud prevention;
                        and provide certain features to you.
                    </Text>
                    <Text as="p">
                        If you receive email from us, we may use certain
                        analytics tools, such as clear GIFs to capture data such
                        as when you open our message or click on any links or
                        banners our email contains. This data allows us to gauge
                        the effectiveness of our communications and marketing
                        campaigns.
                    </Text>
                    <Text as={'h3'} size={'4'} color={'error'}>
                        2. NOTICE CONCERNING DO NOT TRACK{' '}
                    </Text>
                    <Text as="p">
                        Do Not Track (“<b>DNT”</b>) is a privacy preference that
                        users can set in certain web browsers. We are committed
                        to providing you with meaningful choices about the
                        information collected on our website for third party
                        purposes, and that is why we provide the variety of
                        opt-out mechanisms listed below. However, we do not
                        currently recognize or respond to browser-initiated DNT
                        signals. To learn more about Do Not Track, you can do so{' '}
                        <TextLink href="https://allaboutdnt.com/">
                            Here
                        </TextLink>
                        .
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        How We Disclose Your Information
                    </Text>
                    <Text as="p">
                        Statemints will disclose your information in the
                        following ways:
                    </Text>
                    <Text as="h4" size={'4'} textTransform={'upper'}>
                        Affiliates and Subsidiaries{' '}
                    </Text>
                    <Text as="p">
                        We may disclose information we collect within the Jogg
                        Inc. family of companies to deliver products and
                        services to you, ensure a consistent level of service,
                        and enhance our products, services, and your customer
                        experience.{' '}
                    </Text>
                    <Text as="h4" size={'4'} textTransform={'upper'}>
                        Service Providers{' '}
                    </Text>
                    <Text as="p">
                        We provide access to or disclose your information to
                        select third parties who perform services on our behalf.
                        They have access to perform these services but are
                        prohibited from using your information for other
                        purposes. They provide a variety of services to us,
                        including billing, sales, marketing, product content and
                        features, analytics, research, customer service, data
                        storage, security, fraud prevention, payment processing,
                        and legal services.{' '}
                    </Text>
                    <Text as="h4" size={'4'} textTransform={'upper'}>
                        Protection of Statemints and Others{' '}
                    </Text>
                    <Text as="p">
                        By using the Services, you acknowledge and agree that we
                        may access, retain and disclose the information we
                        collect and maintain about you if required to do so by
                        law or in a good faith belief that such access,
                        retention or disclosure is reasonably necessary to: (a)
                        comply with legal process (e.g. a subpoena or court
                        order); (b) enforce our Terms of Service, this Privacy
                        Policy, or other contracts with you, including
                        investigation of potential violations thereof; (c)
                        respond to claims that any content violates the rights
                        of third parties; (d) respond to your requests for
                        customer service; and/or (e) protect the rights,
                        property or personal safety of Statemints, its agents
                        and affiliates, its users and/or the public. This
                        includes exchanging information with other companies and
                        organizations for fraud protection, spam/malware
                        prevention, and similar purposes.{' '}
                    </Text>
                    <Text as="h4" size={'4'} textTransform={'upper'}>
                        Business Transfers{' '}
                    </Text>
                    <Text as="p">
                        As we continue to develop our business, we may buy,
                        merge, or partner with other companies. In such
                        transactions (including in contemplation of such
                        transactions), user information may be among the
                        transferred assets. If a portion or all of our assets
                        are sold or transferred to a third-party, customer
                        information (including your email address) would likely
                        be one of the transferred business assets. If such
                        transfer is subject to additional mandatory restrictions
                        under applicable laws, we will comply with such
                        restrictions.{' '}
                    </Text>
                    <Text as="h4" size={'4'} textTransform={'upper'}>
                        Public Forums{' '}
                    </Text>
                    <Text as="p">
                        Certain features of our Services may make it possible
                        for you to post comments publicly that other users can
                        see. Any information that you submit through such
                        features is not confidential, and we may use it for any
                        purpose (including in testimonials or other marketing
                        materials). Any information you post openly in these
                        ways will be available to the public at large and
                        potentially accessible through third-party search
                        engines. Accordingly, please take care when using these
                        features.{' '}
                    </Text>
                    <Text as="h4" size={'4'} textTransform={'upper'}>
                        Aggregate/Anonymous Information{' '}
                    </Text>
                    <Text as="p">
                        From time to time, we may disclose Aggregate/Anonymous
                        Information about use of the Services, such as by
                        publishing a report on usage trends. The disclosure of
                        such data is unrestricted.{' '}
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        Retention of Your Information{' '}
                    </Text>
                    <Text as="p">
                        We keep your information for no longer than necessary
                        for the purposes for which it is processed. The length
                        of time for which we retain information depends on the
                        purposes for which we collected and used it and/or as
                        required to comply with applicable laws.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        How We Protect Your Information{' '}
                    </Text>
                    <Text as="p">
                        Statemints takes technical and organizational security
                        measures to protect the information provided via the
                        Services from loss, misuse, and unauthorized access,
                        disclosure, alteration, or destruction. However, no
                        Internet or email transmission is ever fully secure or
                        error free. Please keep this in mind when disclosing any
                        information to Statemints via the Internet.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        Third Party Links and Features{' '}
                    </Text>
                    <Text as="p">
                        The Services may contain links to third-party websites
                        such as social media sites, and may also contain
                        third-party plug-ins (such as the Facebook “like” button
                        and Twitter “follow” button). If you choose to use these
                        sites or features, you may disclose your information not
                        just to those third-parties, but also to their users and
                        the public more generally depending on how their
                        services function. We are not responsible for the
                        content or practices of those websites or services. The
                        collection, use, and disclosure of your information will
                        be subject to the privacy policies of the third party
                        websites or services, and not this Privacy Policy. We
                        urge you to read the privacy and security policies of
                        these third-parties.{' '}
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        Children&apos;s Privacy{' '}
                    </Text>
                    <Text as="p">
                        The Services are intended for general audiences and not
                        for children under the age of 13. If we become aware
                        that we have inadvertently collected “personal
                        information” (as defined by the United States Children’s
                        Online Privacy Protection Act) from children under the
                        age of 13 without valid parental consent, we will take
                        reasonable steps to delete it as soon as possible. We do
                        not knowingly process data of EU residents under the age
                        of 16 without parental consent. If we become aware that
                        we have collected data from an EU resident under the age
                        of 16 without parental consent, we will take reasonable
                        steps to delete it as soon as possible. We also comply
                        with other age restrictions and requirements in
                        accordance with applicable local laws.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        Your Rights and Choices
                    </Text>
                    <Text as={'h3'} size={'4'} color={'error'}>
                        1. GENERAL RIGHTS{' '}
                    </Text>
                    <Text as="p">
                        Depending on your jurisdiction, you may have the right
                        to make certain requests regarding your “personal
                        information” or “personal data” (as such terms are
                        defined under applicable law, and collectively referred
                        to herein as “personal information”). Specifically, you
                        may have the right to ask us to:
                    </Text>
                    <Flex
                        as="ul"
                        direction={'col'}
                        css={{
                            listStyleType: 'disc',
                            fontSize: '$3',
                            fontWeight: '$normal',
                            lineHeight: 2,
                            alignItems: 'flex-start',
                            left: '30rem',
                            position: 'relative',
                            paddingRight: '30rem'
                        }}
                    >
                        <li>
                            Inform you about the categories of personal
                            information we collect or disclose about you; the
                            categories of sources of such information; the
                            business or commercial purpose for collecting your
                            personal information; and the categories of third
                            parties with whom we disclose personal information.{' '}
                        </li>
                        <li>
                            Provide you access to and/or a copy of certain
                            personal information we hold about you.
                        </li>
                        <li>
                            Correct or update personal information we hold about
                            you.
                        </li>
                        <li>
                            Delete certain personal information we have about
                            you.
                        </li>
                        <li>
                            Object to or restrict processing of your information
                        </li>
                        <li>
                            Revoke your consent for the processing of your
                            information
                        </li>
                        <li>
                            Transfer your information to a third-party provider
                            of services
                        </li>
                        <li>
                            Opt out of the processing of your personal
                            information for purposes of profiling in furtherance
                            of decisions that produce legal or similarly
                            significant effects, if applicable.
                        </li>
                    </Flex>
                    <Text as="p">
                        As provided in applicable law, you also have the right
                        to not be discriminated against for exercising your
                        rights. Please note that certain information may be
                        exempt from such requests under applicable law. For
                        example, we need to retain certain information to
                        provide our services to you. We also need to take
                        reasonable steps to verify your identity before
                        responding to a request, which may include, at a
                        minimum, depending on the sensitivity of the information
                        you are requesting and the type of request you are
                        making, verifying your name and email address. You are
                        also permitted to designate an authorized agent to
                        submit certain requests on your behalf. In order for an
                        authorized agent to be verified, you must provide the
                        authorized agent with signed, written permission to make
                        such requests or a power of attorney. We may also follow
                        up with you to verify your identity before processing
                        the authorized agent’s request. If you would like
                        further information regarding your legal rights or would
                        like to exercise any of them, please submit a privacy
                        request to us by email at HQ@Jogg.co.
                    </Text>
                    <Text as="p">
                        Depending on applicable law, you may have the right to
                        appeal our decision to deny your request, if applicable.
                        If we deny your request, we will provide you with
                        information on how to appeal the decision, if
                        applicable, in our communications with you.
                    </Text>
                    <Text as={'h3'} size={'4'} color={'error'}>
                        2. OPTING OUT OF MARKETING EMAILS{' '}
                    </Text>
                    <Text as="p">
                        You can unsubscribe from our marketing emails via the
                        unsubscribe link provided in the emails. Please note
                        that it may take us some time, consistent with our legal
                        obligations, to process your request. Even if you opt
                        out from receiving marketing messages from us, you will
                        continue to receive administrative messages from us,
                        such as order confirmations, updates to our policies and
                        practices, or other communications regarding our
                        relationship or transactions with you.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        Legal Bases for Use of Your Information{' '}
                    </Text>
                    <Text as="p">
                        The legal bases for using your information as set out in
                        this Privacy Policy are as follows:
                    </Text>
                    <Flex
                        as="ul"
                        direction={'col'}
                        css={{
                            listStyleType: 'disc',
                            fontSize: '$3',
                            fontWeight: '$normal',
                            lineHeight: 2,
                            alignItems: 'flex-start',
                            left: '30rem',
                            position: 'relative',
                            paddingRight: '30rem'
                        }}
                    >
                        <li>
                            Where use of your information is necessary to
                            perform our obligations under a contract with you
                            (for example, to comply with: the terms of service
                            of our websites which you accept by browsing the
                            websites/registering; and/or our contract to provide
                            our Services to you);
                        </li>
                        <li>
                            Where use of your information is necessary for our
                            legitimate interests or the legitimate interests of
                            others (for example, to provide security for our
                            website and applications; operate our business and
                            our Services; make and receive payments; comply with
                            legal requirements and defend our legal rights;
                            prevent fraud and to know the customer to whom we
                            are providing Services);
                        </li>
                        <li>
                            Where we are required to process information in
                            accordance with an EU Member State legal obligation;
                            or
                        </li>
                        <li>
                            Where we have your consent, in accordance with
                            applicable law.
                        </li>
                    </Flex>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        International Transfer and Privacy Shield
                    </Text>
                    <Text as="p">
                        The information discussed in this Policy is processed in
                        the United States by Statemints, whose principal office
                        in the United States is located at 1800 Vine Street,
                        Hollywood, CA 90028.{' '}
                    </Text>
                    <Text as="p">
                        Statemints complies with the EU-US Privacy Shield
                        Framework and the Swiss-US Privacy Shield Framework as
                        set forth by the U.S. Department of Commerce regarding
                        the collection, use, and retention of personal data from
                        the European Union and Switzerland to the United States,
                        respectively. Statemints has certified to the Department
                        of Commerce that it adheres to the Privacy Shield
                        Principles of Notice, Choice, Accountability for Onward
                        Transfer, Security, Data Integrity and Purpose
                        Limitation, Access and Recourse, Enforcement and
                        Liability. If there is any conflict between the policies
                        in this privacy policy and the Privacy Shield
                        Principles, the Privacy Shield Principles shall govern.
                        To learn more about the Privacy Shield program, and to
                        view our certification page, please visit
                        https://www.privacyshield.gov/.{' '}
                    </Text>
                    <Text as="p">
                        In compliance with the EU-US Privacy Shield and Swiss-US
                        Privacy Shield Principles, Statemints commits to resolve
                        complaints about your privacy and our collection or use
                        of your personal data. European Union or Swiss citizens
                        with inquiries or complaints regarding this privacy
                        policy should first contact Statemints at HQ@jogg.co.
                        Statemints is subject to the investigatory and
                        enforcement powers of the Federal Trade Commission (FTC)
                        with respect to its compliance with the provisions of
                        the EU-US and Swiss-US Privacy Shield.{' '}
                    </Text>
                    <Text as="p">
                        If you are located in the European Union or other
                        regions with laws governing data collection and use that
                        may differ from U.S. law, please note that you are
                        transferring information to a country and jurisdiction
                        that does not have the same data protection laws as your
                        jurisdiction. As described above, we also may
                        subcontract the processing of your data to, or otherwise
                        disclose your data to, other members within the
                        Statemints group of companies, service providers, and
                        business partners in countries other than your country
                        of residence, including the United States, in accordance
                        with applicable law. Such third parties may be engaged
                        in, among other things, the provision of Services to
                        you, the processing of transactions and/or the provision
                        of support services. By providing us with your
                        information, you acknowledge any such transfer, storage
                        or use. Statemints will take reasonable and appropriate
                        steps necessary to ensure that any third party who is
                        acting as a “data processor” under EU and Swiss
                        terminology is processing the personal data we entrust
                        to them in a manner that is consistent with the EU-US
                        and Swiss-US Privacy Shield Principles. Statemints is
                        potentially liable in cases of onward transfer to third
                        parties of data of EU and Swiss individuals received
                        pursuant to the EU-US and Swiss-US Privacy Shield,
                        respectively.{' '}
                    </Text>
                    <Text as="p">
                        If applicable, you may make a complaint to the data
                        protection supervisory authority in the country where
                        you reside. Alternatively you may seek a remedy through
                        local courts if you believe your rights have been
                        breached.{' '}
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        Changes to our Privacy Policy{' '}
                    </Text>
                    <Text as="p">
                        We reserve the right to amend this Privacy Policy at any
                        time to reflect changes in the law, our data collection
                        and use practices, the features of our Services, or
                        advances in technology. We will make the revised Privacy
                        Policy accessible through the Services, so you should
                        review the Privacy Policy periodically. You can know if
                        the Privacy Policy has changed since the last time you
                        reviewed it by checking the &quot;Date of Last
                        Revision&quot; included at the beginning of the
                        document. If we make a material change to the Policy,
                        you will be provided with appropriate notice in
                        accordance with legal requirements. By continuing to use
                        the Services, you are confirming that you have read and
                        understood the latest version of this Privacy Policy.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        Contact Information{' '}
                    </Text>
                    <Text as="p">
                        Please feel free to contact us if you have any questions
                        about Statemints&apos; Privacy Policy or the information
                        practices of the Services. You may contact us as
                        follows:
                    </Text>
                    <Text as="p">1800 Vine StreetHollywood, CA 90028</Text>
                    <TextLink href="mailto:hq@jogg.co">hq@jogg.co</TextLink>
                </VStack>
            </Container>
            <Button
                onClick={scrollToTop}
                className={showButton ? '' : 'hide'}
                css={{
                    position: 'fixed',
                    bottom: '100rem',
                    zIndex: '10',
                    '&.hide': { display: 'none' }
                }}
            >
                Back to the top
            </Button>
        </Flex>
    )
}
