import { useState } from 'react'
import { useEvent } from 'react-use'
import { Button, Container, Flex, Text, TextLink, VStack } from 'ui'
import { HeadingHeader } from './HeadingHeader'

export const TermsOfUse = () => {
    const [showButton, setShowButton] = useState(false)

    const onScrolling = () => {
        if (
            document.body.scrollTop > 400 ||
            document.documentElement.scrollTop > 400
        ) {
            setShowButton(true)
        } else {
            setShowButton(false)
        }
    }

    const scrollToTop = () => {
        document.body.scrollTop = 100
        document.documentElement.scrollTop = 100
    }

    useEvent('scroll', onScrolling, window)

    return (
        <Flex
            direction={'col'}
            justifyContent={'flexStart'}
            css={{
                flex: '1',
                background: '$ivory',
                color: '$dark_lighter'
            }}
        >
            <HeadingHeader
                title="User License Agreement"
                heading="Terms of Use"
                description="Last Updated 1/12/2022"
            />

            <Container
                size="lg"
                css={{
                    p: '50rem $4',
                    '@md': {
                        p: '80rem $4'
                    }
                }}
            >
                <VStack alignItems={'flexStart'} spacing={5}>
                    <Text as="p">
                        This User License Agreement And Terms Of Use
                        (&quot;Agreement&quot;) constitutes a legally binding
                        contract between you and Jogg, Inc., its licensors,
                        suppliers, and successors (collectively, &quot;Jogg,
                        Inc.&quot;) and governs your use of all services,
                        products and/or software of any kind provided by Jogg,
                        Inc. This includes, but is not limited to, any services,
                        products and/or software accessed through the Statemints
                        website, the JOGG website and any other applications in
                        their various forms, which may change from time to time
                        (collectively “JOGG”). You represent that you are
                        authorized to use JOGG and accept the obligations set
                        forth herein on your own behalf or on behalf of another
                        person or entity. By using any of the services, products
                        and/or software provided by JOGG, you agree that your
                        use of JOGG is subject to this Agreement and Jogg,
                        Inc.&apos;s Privacy Policy, as set forth below (the
                        &quot;Privacy Policy&quot;). Any and all unauthorized
                        use of JOGG is strictly prohibited. For the avoidance of
                        doubt, you agree that all of your obligations under this
                        Agreement apply to the use of JOGG by any individuals
                        accessing JOGG through any computers, mobile phones and
                        any other devices owned or controlled by you.
                    </Text>
                    <Text as="p">
                        By your use of Statemints, you acknowledge that you have
                        read, understood and agree to be fully bound by this
                        Agreement. If you do not agree to this Agreement, select
                        the “Cancel” button and do not access or use JOGG in any
                        way, form or method.
                    </Text>
                    <Text as="p">
                        Jogg, Inc. reserves the right in its sole discretion and
                        with or without notice to you, to modify and alter JOGG,
                        including but not limited to its features,
                        specifications, capabilities, functions, programming,
                        availability and all other services and software
                        constituting JOGG (“Changes.”). By continuing to use
                        JOGG after any such Changes, you agree that you are
                        bound by such Changes, and that Jogg, Inc. has no
                        liability to you whatsoever as a result of any such
                        Changes.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        1. Grant of Licenses
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        Provided that you agree and comply with the terms of
                        this Agreement, Jogg, Inc. hereby grants to you a
                        non-exclusive, non-transferable, limited license, to
                        access and use JOGG, to access and watch “Content” (as
                        defined below) and, solely as expressly permitted by
                        Jogg, Inc. in the JOGG services in writing, to
                        reproduce, transmit, display and make written comments
                        on Content.  You acknowledge and agree that the rights
                        granted to you pursuant to this paragraph may change
                        from time to time. “Content” shall mean any and all
                        audio and video materials including, without limitation,
                        film, sound, and other forms of intellectual property,
                        whether in still, live or animated form, which you may
                        access through the use of JOGG.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        2. Liability{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        The JOGG services may permit you to add text to the
                        Content you access, which may include, but is not
                        limited to, creating notes, headlines, or otherwise
                        creating text in connection with the Content
                        (collectively referred to in this Agreement as
                        “Comments”). If you create Comments in connection with
                        the Content, you assume the risks of all such Comments
                        and understand and agree that JOGG is a passive conduit
                        of any such Comments. You acknowledge Jogg, Inc. is not
                        responsible for preventing or identifying Comments that
                        violates any third party rights, and that Jogg, Inc.
                        assumes no responsibility for monitoring for any
                        possible violations of any third party rights. For any
                        violation or suspected violation of any third party
                        rights, you agree to notify Jogg, Inc. in accordance
                        with Jogg, Inc.&apos;s Notification Policy, which is set
                        forth below. Jogg, Inc. makes no representations or
                        warranties as to any Comments made available by any user
                        of JOGG, made accessible through JOGG, including without
                        limitation, whether or not any such Comments violate the
                        rights of any third parties, including without
                        limitation, privacy rights.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        3. Privacy{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        You agree that you have read and agree to comply with
                        the terms of Jogg, Inc.&apos;s Privacy Policy, which can
                        be found{' '}
                        <TextLink href="/privacy-policy">HERE</TextLink>.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        4. Third Party Materials{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        JOGG may include third party components that may be
                        subject to their own, separate license agreements. You
                        assume all risks and liabilities associated with the use
                        of any such third party offerings. Any third party
                        products, Content, services or links displayed on JOGG
                        are not referrals or endorsements of any product,
                        service or provider.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        5. Tracking{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        JOGG may contain software to track and capture activity,
                        including individual user information, to assist Jogg,
                        Inc. in optimizing delivery of Content and for the
                        purposes of developing and facilitating algorithms and
                        reports. Your use of JOGG constitutes acceptance of
                        these features. If you engage in, or assist or enable,
                        for syndication of Content on third party websites, you,
                        and not Jogg, Inc., are solely responsible for notifying
                        each such third party website that Jogg, Inc. may track
                        and capture such information.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        6. Restrictions{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        You are expressly prohibited from: (a) selling, renting,
                        leasing, sublicensing, otherwise transferring or
                        distributing any copies of JOGG to third parties; (b)
                        modifying, translating, reverse engineering,
                        decompiling, or disassembling JOGG or altering any
                        accompanying documentation; (c) creating derivative
                        works based upon JOGG; (d) altering, destroying or
                        otherwise removing any proprietary notices, images or
                        logos displayed, provided on or embedded within JOGG;
                        (e) restricting or inhibiting others use or enjoyment of
                        JOGG; (f) impersonating or otherwise misrepresenting any
                        person or entity, or making false or misleading
                        indications of origin or fact; or (g) interfering with
                        or disrupting JOGG or Jogg, Inc.&apos;s servers or
                        networks connected thereto, through any manner
                        whatsoever, or disobeying any requirements, procedures,
                        policies or regulations of networks connected to JOGG.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        7. Warranty{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        You are solely responsible for all matters arising out
                        of or in connection with any Comments that you create,
                        submit and use through JOGG, including without
                        limitation, ensuring that any Comments you submit or use
                        through JOGG do not include Prohibited Content, as
                        defined in Section 8 below, and that the Comments you
                        create, submit and use through JOGG do not violate the
                        rights of any third party. You represent and warrant
                        that you are solely responsible and liable for: (a)
                        complying with all laws, rules and applicable
                        regulations in connection with any Comments; (b) not
                        utilizing any Prohibited Content in any Comments; and
                        (d) abiding by all the terms of this Agreement. Jogg,
                        Inc. has no obligation (and undertakes no responsibility
                        or liability for any obligation) to review, monitor or
                        oversee any Comments created, distributed or otherwise
                        used in connection with the use of JOGG. Furthermore,
                        Jogg, Inc. assumes no responsibility for protecting or
                        enforcing third party rights with respect to any
                        Comments, including, without limitation, monitoring the
                        Comments, determining whether any Comments are lawful,
                        appropriate or permitted by third parties, for libel,
                        falsehoods, errors or omissions contained in Comments or
                        compliant with FCC or other governmental regulations, or
                        for taking legal action against actual or suspected
                        infringers of Comments. You are solely responsible for
                        maintaining copies of your Comments and shall take
                        precautionary measures to backup and protect them. Jogg,
                        Inc. has no obligation to provide you (or any third
                        party) with backup or archival copies of any Comments
                        you create or distribute via JOGG.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        8. Prohibited Content{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        You shall not, and you warrant that you shall not, use
                        JOGG in any manner in connection with Content, Comments
                        or otherwise that: (a) promotes illegal activity, or
                        provides instructions for illegal activity; (b) exploits
                        images or discloses personally identifiable information
                        of children under eighteen (18) years of age or
                        otherwise harms minors in any way; (c) you do not have
                        the right to use for the purposes of JOGG; (d) is
                        unlawful, harmful, threatening, abusive, violent,
                        harassing, tortious, defamatory, vulgar, obscene,
                        pornographic, libelous, invasive of privacy or publicity
                        rights, hateful or racially, sexually, ethnically or
                        otherwise objectionable; (e) infringes the intellectual
                        property or proprietary rights of any third party; (f)
                        is in any way related to any raffle, sweepstakes,
                        contest or game requiring a fee by participants; (g)
                        directly or indirectly is used for &quot;junk
                        mail&quot;, &quot;spam&quot;, &quot;chain letters&quot;,
                        &quot;pyramid schemes&quot;, or any other like form of
                        solicitation; and/or (h) contains any malicious or
                        invasive software, or that could diminish the quality
                        of, interfere with the performance of, or impair the
                        functionality of JOGG (collectively, the “Prohibited
                        Content”). Jogg, Inc. reserves the right to remove any
                        Comments for any or no reason in its sole disretion,
                        including, but not limited to any Comments that violate
                        these terms or are otherwise reported for inappropriate
                        content. Additionally, You are prohibited from
                        submitting, distributing or in any way using any Content
                        or Comments that constitutes (as determined by Jogg,
                        Inc. in its sole discretion) advertising, offering for
                        sale, or selling any item, including, but not limited
                        to, any item prohibited from advertising or selling by
                        any applicable local, state, national, or international
                        law, including regulations having the force of law, or
                        advertise, offer for sale, or sell any of the following
                        items:
                    </Text>
                    <Flex
                        as="ol"
                        direction={'col'}
                        css={{
                            listStyleType: 'lower-latin',
                            fontSize: '$3',
                            fontWeight: '$normal',
                            lineHeight: 2,
                            alignItems: 'flex-start',
                            left: '50rem',
                            position: 'relative',
                            paddingRight: '30rem'
                        }}
                    >
                        <li>Any firearms, explosives, or weapons.</li>
                        <li>
                            Any food that is not packaged or does not comply
                            with all laws governing the sale of food to
                            consumers by commercial merchants.
                        </li>
                        <li>Any alcoholic beverages.</li>
                        <li>
                            Any tobacco products for human consumption,
                            including, without limitation, cigarettes and
                            cigars.
                        </li>
                        <li>
                            Any items that are indecent or obscene, that are
                            hateful or racially, sexually, ethnically or
                            otherwise objectionable, that contain child
                            pornography, that are otherwise pornographic in
                            nature, or are harmful to minors.
                        </li>
                        <li>Any controlled substances or pharmaceuticals.</li>
                        <li>Any items that are counterfeit or stolen.</li>
                        <li>Any dangerous items.</li>
                        <li>
                            Any goods or services that do not, in fact, exist.
                        </li>
                        <li>Any registered or unregistered securities.</li>
                        <li>
                            Any items that violate or infringe the rights of
                            other parties.
                        </li>
                        <li>
                            Any items that you do not have the legal right to
                            sell.
                        </li>
                        <li>
                            Any items where doing so through JOGG would cause
                            Jogg, Inc. to violate any law.
                        </li>
                    </Flex>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        9. Indemnification{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        You shall defend, indemnify and hold Jogg, Inc. harmless
                        against any and all claims, damages, costs and expenses
                        (including reasonable attorneys&apos; fees and
                        litigation expenses) arising out of or in connection
                        with (i) your breach of this Agreement, including but
                        not limited to any of the representations or warranties
                        set forth in this Agreement, and without exception, the
                        warranties you provided in Section 7 and 8 above; and
                        (ii) any claims made by any third parties resulting from
                        your use of JOGG, including to any Comments you create
                        or any prohibited use of any Content.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        10. Preservation & Disclosure{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        You expressly acknowledge and agree that Jogg, Inc. may
                        preserve Content or Comments and may also disclose
                        Content or Comments if required to do so by law or in
                        the good faith belief that such preservation or
                        disclosure is reasonably necessary to: (a) comply with
                        legal process; (b) enforce this Agreement; (c) respond
                        to claims that any Content or Comments violates the
                        rights of third-parties; or (d) protect the rights,
                        property, or personal safety of Jogg, Inc., its users
                        and the public. You acknowledge and agree that the
                        technical processing and transmission of the Content or
                        Comments may involve (a) transmissions over various
                        networks; and (b) changes to conform and adapt to
                        technical requirements of connecting networks or
                        devices. You further acknowledge and agree that other
                        data collected and maintained by Jogg, Inc. with regard
                        to its users may be disclosed in accordance with Jogg,
                        Inc.&apos;s Privacy Policy as set forth in Section 3
                        above.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        11. Age Restriction{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        Use of JOGG is not intended for minors under the age of
                        13 (&quot;Minors&quot;). Minors are not authorized by
                        Jogg, Inc. to use JOGG, even if Minors accept the terms
                        of this Agreement. Parents or guardians may authorize
                        users between the ages of 13 and 17 to use JOGG, but
                        only on the condition that such user&apos;s parents or
                        legal guardians assume all responsibility and legal
                        liability for the conduct of such user including,
                        without limitation, monitoring the user&apos;s access
                        and use of JOGG, as well as any Content that is accessed
                        through JOGG and any Comments made via JOGG. If Jogg,
                        Inc. learns that anyone under the age of 13 is using
                        JOGG, Jogg, Inc. will require verified parental or
                        guardian consent, in accordance with the Children&apos;s
                        Online Privacy Protection Act of 1998
                        (&quot;COPPA&quot;). You agree to not use JOGG in a
                        manner that seeks the participation of Minors in
                        violation of the terms set forth in this Section, and
                        you agree to not submit, distribute or in any way use
                        any Content or Comments that you know or should
                        reasonably know in the application of reasonable
                        prudence, is in violation of the terms set forth in this
                        Section.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        12. Updates{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        Any updates that Jogg, Inc. may make to JOGG shall be
                        subject to all of the terms and conditions of this
                        Agreement. Jogg, Inc. may, from time to time, post
                        notices on JOGG, other Jogg, Inc. websites or blogs, or
                        other public communications tools (such as through
                        Facebook, X, and other third party services), to explain
                        any such updates and provide instructions to users
                        associated with such updates.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        13. Compliance with U.S. Laws{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        You shall adhere to all applicable state, federal, local
                        and international laws and treaties in all jurisdictions
                        in which you use JOGG, including, without limitation,
                        all end-user, end-use and destination restrictions
                        issued by U.S. and other governments and the U.S. Export
                        Administration Act and its associated regulations. For
                        clarity, you, and not Jogg, Inc., shall be responsible
                        for ensuring that your use of JOGG, does not constitute
                        a criminal offense, give rise to civil liability or
                        otherwise violates any applicable law, regulation or
                        ordinance.
                    </Text>
                    {/** waiting on updated text for termination from design */}
                    <Text as="h2" size={'5'} weight={'bold'}>
                        14. Termination{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        Jogg, Inc. may terminate or suspend your access to JOGG
                        for any or no reason in the sole discretion of Jogg,
                        Inc. A reason for termination or suspension may include,
                        but is not limited to: (a) should you fail to comply
                        with a term or condition of this Agreement and/or any
                        applicable services agreement between you and Jogg,
                        Inc.; (b) for any conduct that Jogg, Inc. believes is or
                        may be directly or indirectly harmful to other users, to
                        Jogg, Inc. or its subsidiaries, affiliates, or business
                        contractors, or to other third parties; and/or (c) for
                        any conduct that violates any local, state, federal, or
                        foreign laws or regulations.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        15. Intellectual Property{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        Jogg, Inc. owns all right, title and interest in and to
                        JOGG including associated intellectual property rights
                        under copyright, trade secret, patent, trademark and/or
                        other applicable domestic and international laws
                        (collectively, “Jogg IP”). Any and all trademarks that
                        Jogg, Inc. uses in connection with JOGG are owned by
                        Jogg, Inc. and any goodwill associated with the use of
                        such marks shall inure to the benefit of Jogg, Inc.
                        Jogg, Inc. is not required to accept unsolicited
                        feedback. If you provide any feedback, whether solicited
                        or not, about JOGG to Jogg, Inc., Jogg, Inc. shall own
                        all rights in and to such feedback and any derivative
                        technologies developed on such feedback. You shall take
                        all necessary actions to affect Jogg, Inc.&apos;s rights
                        in and to such feedback.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        16. Disclaimer of Warranties{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        YOU UNDERSTAND AND AGREE THAT JOGG SHALL NOT UNDER ANY
                        CIRCUMSTANCES BE LIABLE FOR ANY DAMAGES TO YOU
                        WHATSOEVER ARISING AS A RESULT OF ANY CONTENT THAT YOU
                        USE THROUGH JOGG AND THAT YOU ASSUME ALL RISKS ARISING
                        FROM THE USE OF ANY CONTENT YOU OBTAIN THROUGH JOGG AND
                        ANY COMMENTS YOU CREATE THROUGH JOGG. JOGG, INC. MAKES
                        NO REPRESENTATION, WARRANTY, OR GUARANTEE OF THE
                        SUITABILITY OF JOGG FOR YOUR PURPOSES OR THAT THE USE OF
                        JOGG SHALL BE SECURE, UNINTERRUPTED OR ERROR-FREE, OR
                        THAT JOGG SHALL FUNCTION PROPERLY IN COMBINATION WITH
                        ANY THIRD PARTY TECHNOLOGY, HARDWARE, SOFTWARE, SYSTEMS
                        OR DATA. THE TECHNOLOGY IS PROVIDED &quot;AS IS&quot;
                        AND ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES,
                        WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE,
                        INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF
                        MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR
                        PURPOSE, OR NON-INFRINGEMENT ARE HEREBY DISCLAIMED TO
                        THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. THE
                        TECHNOLOGY MAY BE SUBJECT TO INTERRUPTION, LIMITATIONS,
                        DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF
                        INTERNET APPLICATIONS AND ELECTRONIC COMMUNICATIONS AND
                        JOGG, INC. IS NOT RESPONSIBLE FOR ANY SUCH DELAYS,
                        DELIVERY FAILURES, OR ANY OTHER DAMAGE RESULTING FROM
                        EVENTS BEYOND JOGG, INC.&apos;S REASONABLE CONTROL.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        17. Limitation of Liability{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                        EVENT SHALL JOGG, INC. BE LIABLE FOR ANY CONSEQUENTIAL,
                        INCIDENTAL, DIRECT, INDIRECT, SPECIAL, PUNITIVE, OR
                        OTHER DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION,
                        DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS
                        INTERRUPTION, LOSS OF BUSINESS INFORMATION, INTELLECTUAL
                        PROPERTY INFRINGEMENT OR OTHER PECUNIARY LOSS) ARISING
                        OUT OF THIS AGREEMENT OR THE USE OF OR INABILITY TO USE
                        JOGG, EVEN IF JOGG, INC. HAS BEEN ADVISED OF THE
                        POSSIBILITY OF SUCH DAMAGES. YOUR EXCLUSIVE REMEDY AND
                        JOGG, INC.&apos;S TOTAL AGGREGATE LIABILITY RELATING TO,
                        ARISING OUT OF, IN CONNECTION WITH, OR INCIDENTAL TO
                        THIS AGREEMENT FOR ANY REASON SHALL BE LIMITED TO THE
                        ACTUAL DIRECT DAMAGES INCURRED BY YOU, BUT IN NO EVENT
                        GREATER THAN ONE THOUSAND U.S. DOLLARS ($1,000.00). THIS
                        LIMITATION APPLIES TO ALL CAUSES OF ACTION OR CLAIMS IN
                        THE AGGREGATE FOR ANY REASON. BECAUSE SOME STATES/
                        JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION
                        OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES,
                        THE ABOVE DISCLAIMER MAY NOT APPLY TO YOU. THE
                        PROVISIONS OF THIS SECTION DO NOT WAIVE OR LIMIT JOGG,
                        INC.&apos;S ABILITY TO OBTAIN INJUNCTIVE OR OTHER
                        EQUITABLE RELIEF FOR BREACH OF THIS AGREEMENT.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        18. Governing Law; Dispute Resolution{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                        EVENT SHALL JOGG, INC. BE LIABLE FOR ANY CONSEQUENTIAL,
                        INCIDENTAL, DIRECT, INDIRECT, SPECIAL, PUNITIVE, OR
                        OTHER DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION,
                        DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS
                        INTERRUPTION, LOSS OF BUSINESS INFORMATION, INTELLECTUAL
                        PROPERTY INFRINGEMENT OR OTHER PECUNIARY LOSS) ARISING
                        OUT OF THIS AGREEMENT OR THE USE OF OR INABILITY TO USE
                        JOGG, EVEN IF JOGG, INC. HAS BEEN ADVISED OF THE
                        POSSIBILITY OF SUCH DAMAGES. YOUR EXCLUSIVE REMEDY AND
                        JOGG, INC.&apos;S TOTAL AGGREGATE LIABILITY RELATING TO,
                        ARISING OUT OF, IN CONNECTION WITH, OR INCIDENTAL TO
                        THIS AGREEMENT FOR ANY REASON SHALL BE LIMITED TO THE
                        ACTUAL DIRECT DAMAGES INCURRED BY YOU, BUT IN NO EVENT
                        GREATER THAN ONE THOUSAND U.S. DOLLARS ($1,000.00). THIS
                        LIMITATION APPLIES TO ALL CAUSES OF ACTION OR CLAIMS IN
                        THE AGGREGATE FOR ANY REASON. BECAUSE SOME STATES/
                        JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION
                        OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES,
                        THE ABOVE DISCLAIMER MAY NOT APPLY TO YOU. THE
                        PROVISIONS OF THIS SECTION DO NOT WAIVE OR LIMIT JOGG,
                        INC.&apos;S ABILITY TO OBTAIN INJUNCTIVE OR OTHER
                        EQUITABLE RELIEF FOR BREACH OF THIS AGREEMENT.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        19. Entire Agreement{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        This Agreement constitutes the complete and exclusive
                        agreement between you and Jogg, Inc. with respect to its
                        subject matter. If any provision of this Agreement is
                        held unenforceable for any reason, such provision shall
                        be reformed only to the extent necessary to make it
                        enforceable and such decision shall not affect the
                        enforceability of such provision under other
                        circumstances, or of the remaining provisions hereof
                        under all circumstances.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        20. Enforcement
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        The failure of Jogg, Inc. to enforce any right or
                        provision in this Agreement shall not constitute a
                        waiver of such right or provision.
                    </Text>
                    <Text as="h2" size={'5'} weight={'bold'}>
                        21. Relationship{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        Nothing in this Agreement shall create a joint venture,
                        partnership, employment, agency or exclusive
                        relationship between you and Jogg, Inc. as a result of
                        this Agreement or your use of JOGG as authorized
                        hereunder.
                    </Text>

                    <Text as="h2" size={'5'} weight={'bold'}>
                        22. Notification Policy{' '}
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        Jogg, Inc. respects the intellectual property rights of
                        others and expects you to do the same. In accordance
                        with the Digital Millennium Copyright Act of 1998, the
                        text of which may be found on the U.S. Copyright Office
                        at{' '}
                        <TextLink
                            href="https://www.copyright.gov/legislation/dmca.pdf"
                            style={{ overflowWrap: 'anywhere' }}
                        >
                            HTTPS://WWW.COPYRIGHT.GOV/LEGISLATION/DMCA.PDF
                        </TextLink>
                        , Jogg, Inc. will respond expeditiously to claims of
                        potential copyright infringement committed using JOGG.
                        Copyright owners or a party authorized to act on behalf
                        of an owner, should report potential copyright
                        infringement by completing the following DMCA notice and
                        delivering it to the Jogg, Inc. Designated Copyright
                        Agent. Upon receipt, Jogg, Inc. will take whatever
                        action, in its sole discretion, it deems appropriate,
                        including removal of the potentially infringing material
                        and/or termination of the potential infringer&apos;s
                        Account. Nothing in this Agreement shall create a joint
                        venture, partnership, employment, agency or exclusive
                        relationship between you and Jogg, Inc. as a result of
                        this Agreement or your use of JOGG as authorized
                        hereunder.
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        Please send either an email to{'  '}
                        <TextLink href="mailto:hq@jogg.co">
                            HQ@JOGG.CO
                        </TextLink>{' '}
                        (Subject: DMCA Notification), or letter to the address
                        provided below with all of the following information:
                    </Text>
                    <Flex
                        as="ol"
                        direction={'col'}
                        css={{
                            listStyleType: 'lower-latin',
                            fontSize: '$3',
                            fontWeight: '$normal',
                            lineHeight: 2,
                            alignItems: 'flex-start',
                            left: '50rem',
                            position: 'relative',
                            paddingRight: '30rem'
                        }}
                    >
                        <li>
                            Identify the copyrighted work claimed to have been
                            infringed, or, if multiple copyrighted works at a
                            single online site are covered by a single
                            notification, a representative list of such works at
                            that site.
                        </li>
                        <li>
                            Identify the material that is claimed to be
                            infringing or to be the subject of infringing
                            activity and that is to be removed or access to
                            which is to be disabled, and information reasonably
                            sufficient to locate the material. Providing URLs in
                            the body of an email is the best way to help Jogg,
                            Inc. locate Content quickly.
                        </li>
                        <li>
                            Provide Information reasonably sufficient to permit
                            Jogg, Inc. to contact you, the complaining party,
                            such as an address, telephone number, and, if
                            available, an electronic mail address.
                        </li>
                        <li>
                            A statement that you, as the complaining party, or
                            as one who has authorization to act on behalf of the
                            copyright owner, has a good faith belief that use of
                            the material in the manner complained of is not
                            authorized by the copyright owner, its agent, or the
                            law. As an example, &quot;I hereby state that I have
                            a good faith belief that the disputed use of the
                            copyrighted material is not authorized by the
                            copyright owner or its agent.&quot;
                        </li>
                        <li>
                            A statement that the information in the notification
                            is accurate, and under penalty of perjury, that the
                            complaining party is authorized to act on behalf of
                            the owner of an exclusive right that is allegedly
                            infringed. As an example, &quot;I hereby state that
                            the information in this notice is accurate and,
                            under penalty of perjury, that I am the owner, or
                            authorized to act on behalf of the owner, of the
                            copyright or of an exclusive right under the
                            copyright that is allegedly infringed.&quot;
                        </li>
                        <li>
                            Provide your full legal name and your electronic or
                            physical signature.
                        </li>
                    </Flex>
                    <Text as="p" css={{ ml: '20rem' }}>
                        Such written notice should be sent to Jogg, Inc. as
                        follows: Jogg, Inc., 1800 Vine Street, Hollywood, CA
                        90028
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        The process for counter-notifications is governed by
                        Section 512(g) of the Digital Millennium Copyright Act:{' '}
                        <TextLink
                            href="https://www.copyright.gov/legislation/dmca.pdf"
                            style={{ overflowWrap: 'anywhere' }}
                        >
                            HTTPS://WWW.COPYRIGHT.GOV/LEGISLATION/DMCA.PDF
                        </TextLink>
                        . To file a counter-notification with Jogg, Inc., a
                        written communication that sets forth the items
                        specified below must be provided.
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        Please note that under Section 512(f) of the Copyright
                        Act, any person who knowingly materially misrepresents
                        that material or activity was removed or disabled by
                        mistake or misidentification may be subject to
                        liability. Jogg, Inc. will terminate any User who is a
                        repeat infringer. Accordingly, any User that is not sure
                        whether certain material infringes the copyright of
                        others, should contact an attorney.
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        Elements of Counter-Notification. To file a
                        Counter-Notification, please send either an email to{' '}
                        <TextLink href="mailto:hq@jogg.co">HQ@JOGG.CO</TextLink>
                        (Subject: DMCA Counter-Notification) or a letter to the
                        address provided below with all of the following
                        information, with the following format (including
                        section numbers):
                    </Text>
                    <Flex
                        as="ol"
                        direction={'col'}
                        css={{
                            listStyleType: 'lower-latin',
                            fontSize: '$3',
                            fontWeight: '$normal',
                            lineHeight: 2,
                            alignItems: 'flex-start',
                            left: '50rem',
                            position: 'relative',
                            paddingRight: '30rem'
                        }}
                    >
                        <li>
                            Identify the specific URLs of material that Jogg,
                            Inc. has removed or to which Jogg, Inc. has disabled
                            access.
                        </li>
                        <li>
                            Provide your full name, address, telephone number,
                            and email address.
                        </li>
                        <li>
                            Provide a statement that you consent to the
                            jurisdiction of the Federal District Court for the
                            judicial district in which your address is located
                            (or the United States District Court, Central
                            District of California if your address is outside of
                            the United States), and that you will accept service
                            of process from the person who provided notification
                            or an agent of such person.
                        </li>
                        <li>
                            Include the following statement: &quot;I swear,
                            under penalty of perjury, that I have a good faith
                            belief that the material was removed or disabled as
                            a result of a mistake or misidentification of the
                            material to be removed or disabled.&quot;
                        </li>
                        <li>
                            Sign the notice. If you are providing notice by
                            e-mail, a scanned physical signature or a valid
                            electronic signature will be accepted.
                        </li>
                    </Flex>
                    <Text as="p" css={{ ml: '20rem' }}>
                        Such written notice should be sent to Jogg, Inc.&apos;s
                        as follows: Jogg, Inc., 1800 Vine Street, Hollywood, CA
                        90028
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        After Jogg, Inc. receives a counter-notification, Jogg,
                        Inc. will forward it to the party who submitted the
                        original claim of copyright infringement. Please note
                        that when Jogg, Inc. forwards the counter-notification,
                        it will includes any personal information provided to
                        Jogg, Inc. and submitting a counter-notification
                        requires unencumbered consent to having such information
                        revealed in this way. Jogg, Inc. will not forward the
                        counter-notification to any party other than the
                        original claimant.
                    </Text>
                    <Text as="p" css={{ ml: '20rem' }}>
                        After Jogg, Inc. sends out the counter-notification, the
                        claimant must then notify Jogg, Inc. within ten (10)
                        days that he or she has filed an action seeking a court
                        order to restrain the infringer from engaging in
                        infringing activity relating to the material distributed
                        via JOGG. If Jogg, Inc. receives such notification,
                        Jogg, Inc. will be unable to restore the Content to JOGG
                        until resolution of any court action. If Jogg, Inc. does
                        not receive such notification, Jogg, Inc. may, at its
                        sole discretion, reinstate the Content.
                    </Text>
                </VStack>
            </Container>
            <Button
                onClick={scrollToTop}
                className={showButton ? '' : 'hide'}
                css={{
                    position: 'fixed',
                    bottom: '100rem',
                    zIndex: '10',
                    '&.hide': { display: 'none' }
                }}
            >
                Back to the top
            </Button>
        </Flex>
    )
}
