import classNames from 'classnames'
import { truncate } from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { Highlight } from 'react-instantsearch'
import { useMediaQuery } from 'react-responsive'
import CategoryFolderIcon from 'share-svgs/svgs/folder-category.svg'
import FolderIcon from 'share-svgs/svgs/folder.svg'
import {
    Badge,
    Box,
    Flex,
    Grid,
    Heading,
    HStack,
    Skeleton,
    Text,
    VStack
} from 'ui'

export default function Hit({ hit }: { hit: any }) {
    const router = useRouter()
    const laptopOrAbove = useMediaQuery({ query: '(min-width: 1024px)' })

    const sessionLink = useMemo(() => {
        if (process.env.APP_NAME === 'statemints') {
            if (router.query[`${process.env.SEARCH_INDEX_NAME}[query]`]) {
                const paramName = `${process.env.SEARCH_INDEX_NAME}[query]`
                const paramValue = router.query[paramName] as string
                const queryString = new URLSearchParams({
                    [paramName]: paramValue
                }).toString()
                return `/session/${hit.h}?${queryString}`
            }

            return `/session/${hit.h}`
        }

        return `/dashboard/session/${hit.h}`
    }, [router, hit.h])

    return (
        <Box
            bg={'raven_black'}
            css={{
                padding: '$5 $6',
                marginBottom: '$2',
                borderRadius: '15px',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                '@lg': {
                    flexDirection: 'row'
                }
            }}
        >
            <HitImg
                sessionLink={sessionLink}
                imgSrc={`${process.env.API_URL}/session-thumbnail/${hit.h}?rendition=jpg-desktop`}
                alt={hit.dn}
            />

            <VStack
                spacing={'2'}
                alignItems={'flexStart'}
                css={{
                    'mark.ais-Highlight-highlighted': {
                        background: 'transparent',
                        color: '$deep_aqua'
                    }
                }}
            >
                <Grid css={{ gridTemplateColumns: '1fr 1fr' }}>
                    <HStack spacing={'2'} widthAuto>
                        <Link href={sessionLink}>
                            <Heading
                                as="h2"
                                size="2"
                                textTransform={'capitalize'}
                                title={hit.dn}
                                css={{
                                    maxWidth: '250rem',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    '@lg': {
                                        maxWidth: '400rem'
                                    }
                                }}
                            >
                                <Highlight attribute="dn" hit={hit} />
                            </Heading>
                        </Link>

                        {hit.private === 1 ? (
                            <Badge text={'private'} textSize="2" />
                        ) : null}
                    </HStack>
                </Grid>

                <HitLocation hit={hit} laptopOrAbove={laptopOrAbove} />

                <Text
                    as="p"
                    color={'gray'}
                    css={{
                        'mark.ais-Highlight-highlighted': {
                            background: 'transparent',
                            color: '$deep_aqua',
                            fontWeight: 'bold'
                        }
                    }}
                >
                    {hit.t && hit.t.short_text && (
                        <Highlight attribute="t.short_text" hit={hit} />
                    )}
                </Text>
            </VStack>
        </Box>
    )
}

const HitImg = ({
    sessionLink,
    imgSrc,
    alt
}: {
    sessionLink: string
    imgSrc: string
    alt: string
}) => {
    const [isError, setIsError] = useState(false)
    const [imageLoaded, setImageLoaded] = useState(false)

    return (
        <Box
            className={classNames({ 'img-ready': imageLoaded })}
            css={{
                width: '100%',
                marginBottom: '10px',
                position: 'relative',
                a: {
                    position: 'absolute',
                    inset: 0,
                    zIndex: 1
                },
                img: {
                    visibility: 'hidden'
                },
                '&.img-ready': {
                    objectFit: 'contain',
                    backgroundColor: 'black',
                    img: {
                        visibility: 'visible'
                    }
                },
                '@lg': {
                    marginBottom: '0',
                    maxWidth: '150px',
                    marginRight: '20px'
                }
            }}
        >
            <Link href={sessionLink}>
                <span className="sr-only">link to {alt}</span>
            </Link>
            <Box
                css={{
                    position: 'relative',
                    width: '100%',
                    aspectRatio: '16/9',
                    img: {
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        objectFit: 'cover'
                    }
                }}
            >
                <img
                    width={150}
                    height={80}
                    src={isError ? '/audio-wave.webp' : imgSrc}
                    alt={alt}
                    onLoad={() => setImageLoaded(true)}
                    onError={() => setIsError(true)}
                />
                {!imageLoaded && (
                    <Skeleton
                        css={{
                            width: '150px',
                            height: '80px',
                            borderRadius: '0',
                            $$backgroundColor: '#2D3035',
                            $$backgroundLoadingColor: 'hsl(215, 10%, 22%)'
                        }}
                    />
                )}
            </Box>
        </Box>
    )
}

const HitLocation = ({
    hit,
    laptopOrAbove
}: {
    hit: any
    laptopOrAbove: boolean
}) => {
    return (
        <Flex
            css={{
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                '@lg': {
                    justifyContent: 'flexEnd'
                }
            }}
        >
            {hit.brand ? (
                <Text
                    weight={'bold'}
                    color={'ivory'}
                    textTransform={'upper'}
                    size={'2'}
                    title={hit.brand.name}
                >
                    {laptopOrAbove
                        ? hit.brand.name
                        : truncate(hit.brand.name, {
                              length: 20
                          })}
                </Text>
            ) : (
                <Text
                    color={'ivory'}
                    textTransform={'upper'}
                    size={'2'}
                    weight={'bold'}
                >
                    no brand
                </Text>
            )}

            {hit.category ? (
                <>
                    <Text
                        color="gray"
                        weight="light"
                        css={{
                            m: '0 $1'
                        }}
                    >
                        /
                    </Text>

                    <HStack spacing={'1'} widthAuto>
                        <FolderIcon
                            style={{
                                width: '20rem'
                            }}
                        />
                        <Text
                            as="span"
                            weight={'bold'}
                            color={'ivory'}
                            textTransform={'upper'}
                            size={'2'}
                            title={hit.category.folder.name}
                        >
                            {laptopOrAbove
                                ? hit.category.folder.name
                                : truncate(hit.category.folder.name, {
                                      length: 20
                                  })}
                        </Text>
                    </HStack>

                    <Text
                        color="gray"
                        weight="light"
                        css={{
                            m: '0 $1'
                        }}
                    >
                        /
                    </Text>

                    <HStack spacing={'1'} widthAuto>
                        <CategoryFolderIcon
                            style={{
                                width: '20rem'
                            }}
                        />
                        <Text
                            as="span"
                            weight={'bold'}
                            color={'ivory'}
                            textTransform={'upper'}
                            size={'2'}
                            title={hit.category.name}
                        >
                            {laptopOrAbove
                                ? hit.category.name
                                : truncate(hit.category.name, {
                                      length: 20
                                  })}
                        </Text>
                    </HStack>
                </>
            ) : null}
        </Flex>
    )
}
