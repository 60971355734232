import { styled } from '../../theme/stitches.config'

export const LinkButton = styled('button', {
    $$fontColor: '$colors$ocean_blue',
    color: '$$fontColor',
    fontWeight: '$bold',
    letterSpacing: '$1',
    display: 'inline',
    textTransform: 'uppercase',
    variants: {
        size: {
            lg: {
                fontSize: '$4'
            },
            med: {
                fontSize: '$3'
            },
            sm: {
                fontSize: '$2'
            }
        },
        weight: {
            normal: {
                fontWeight: '$normal'
            },
            bold: {
                fontWeight: '$bold'
            },
            light: {
                fontWeight: '$light'
            }
        },
        inline: {
            true: {
                display: 'inline'
            }
        },
        flexNoShrink: {
            true: {
                flexShrink: '0'
            }
        }
    }
})
