import { useRouter } from 'next/router'
import {
    Configure,
    Hits,
    Pagination,
    Stats,
    useSearchBox,
    useStats
} from 'react-instantsearch'
import { useMediaQuery } from 'react-responsive'
import RadioItemButtonIcon from 'share-svgs/svgs/radio-item-button.svg'
import {
    Box,
    Container,
    Flex,
    Grid,
    HStack,
    RadioGroup,
    RadioGroupItem,
    SearchInput,
    Text,
    VStack
} from 'ui'
import { styled } from 'ui/theme/stitches.config'
import { useCurrentSearchPreference } from '../SearchInput/current-search-preference'
import Hit from './Hit'
import PersistentDrawer from './PersistentDrawer'
import RefinementListAside from './RefinementListAside'

export function InstantSearch() {
    const router = useRouter()
    const { query, refine } = useSearchBox()
    const { nbHits } = useStats()

    const { setSearchPreference } = useCurrentSearchPreference()
    const { searchPreference } =
        useCurrentSearchPreference() ||
        JSON.parse(localStorage.searchPreference)

    const laptopOrAbove = useMediaQuery({ query: '(min-width: 1024px)' })
    const handleSetSearchPreference = (value: string) => {
        const searchPreference = value as 'partial' | 'exact'
        setSearchPreference(searchPreference)
        searchPreference === 'exact' && refine(`"${query}"`)
        searchPreference === 'partial' && refine(query.replace(/"/g, ''))
    }

    return (
        <Container
            size="xl"
            css={{
                padding: '10px 20px 50px'
            }}
        >
            <Grid
                css={{
                    gridTemplateColumns: '1fr',
                    '@lg': {
                        gridTemplateColumns: '300rem 1fr'
                    }
                }}
            >
                {laptopOrAbove ? (
                    <RefinementListAside />
                ) : (
                    <PersistentDrawer>
                        <Box
                            css={{
                                padding: '20px'
                            }}
                        >
                            <RefinementListAside />
                        </Box>
                    </PersistentDrawer>
                )}

                <VStack
                    alignItems={'flexStart'}
                    css={{
                        padding: '$3 0',
                        '@lg': {
                            padding: '$3'
                        }
                    }}
                >
                    <SearchInput
                        query={query}
                        onSearch={(query) => {
                            refine(query)
                        }}
                        defaultInputValue={
                            router.query[
                                `${process.env.SEARCH_INDEX_NAME}[query]`
                            ]
                                ? (
                                      router.query[
                                          `${process.env.SEARCH_INDEX_NAME}[query]`
                                      ] as string
                                  ).replace(/"/g, '')
                                : ''
                        }
                        searchOnSubmit
                        disableSearchPreference
                    />

                    <Flex
                        css={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            '& > :not(:first-child)': {
                                marginTop: '$1'
                            },

                            '@md': {
                                flexDirection: 'row',
                                alignItems: 'center',

                                '& > :not(:first-child)': {
                                    marginTop: 0,
                                    marginLeft: '$3'
                                }
                            }
                        }}
                    >
                        <Text
                            textTransform={'upper'}
                            weight={'bold'}
                            size={'3'}
                            letterSpacing
                        >
                            Search Option
                        </Text>
                        <RadioGroup
                            value={searchPreference}
                            onValueChange={handleSetSearchPreference}
                        >
                            <HStack spacing={{ '@initial': '1', '@md': '3' }}>
                                <RadioGroupItem
                                    value={'partial'}
                                    css={{
                                        fontSize: '14rem',
                                        p: '2rem',
                                        '@md': { p: '10rem' }
                                    }}
                                >
                                    <RadioItemButtonIcon /> Partial Match
                                </RadioGroupItem>
                                <RadioGroupItem
                                    value={'exact'}
                                    css={{
                                        fontSize: '14rem',
                                        p: '2rem',
                                        '@md': { p: '10rem' }
                                    }}
                                >
                                    <RadioItemButtonIcon /> Exact Match
                                </RadioGroupItem>
                            </HStack>
                        </RadioGroup>
                    </Flex>

                    {nbHits > 0 && <Stats />}

                    <Box bRadius={'1'}>
                        <Hits hitComponent={Hit} />
                        {!nbHits && (
                            <Text as="p" size={'4'}>
                                There&apos;s no result for{' '}
                                {query.replace(/"/g, '')}
                            </Text>
                        )}
                    </Box>

                    {nbHits > 1 ? (
                        <StyledPagination>
                            <Pagination
                                totalPages={laptopOrAbove ? undefined : 5}
                            />
                        </StyledPagination>
                    ) : null}
                </VStack>
            </Grid>

            <Configure hitsPerPage={laptopOrAbove ? 10 : 5} />
        </Container>
    )
}

const StyledPagination = styled(Box, {
    width: '100%',
    '.ais-Pagination': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    '.ais-Pagination-list': {
        display: 'flex'
    },
    '.ais-Pagination-item--page .ais-Pagination-link': {
        height: '30rem',
        width: '30rem',
        m: '0 2.5rem',
        color: '$deep_aqua',
        borderRadius: '$round',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '$3',
        fontWeight: 'bold',

        '&:hover': {
            background: '$deep_aqua',
            color: '$ivory'
        }
    },
    '.ais-Pagination-item--previousPage, .ais-Pagination-item--nextPage, .ais-Pagination-item--firstPage, .ais-Pagination-item--lastPage':
        {
            width: '27rem',
            height: '27rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
    '.ais-Pagination-item--previousPage .ais-Pagination-link, .ais-Pagination-item--nextPage .ais-Pagination-link, .ais-Pagination-item--firstPage .ais-Pagination-link, .ais-Pagination-item--lastPage .ais-Pagination-link':
        {
            lineHeight: '.8',
            fontSize: '20rem'
        },
    'a.ais-Pagination-link': {
        color: '$deep_aqua',
        fontWeight: 'bolder'
    },
    '.ais-Pagination-item--disabled .ais-Pagination-link': {
        color: '$gray'
    },
    '.ais-Pagination-item--selected  .ais-Pagination-link': {
        background: '$deep_aqua',
        color: '$ivory'
    }
})
