import classNames from 'classnames'
import CategoryFolderIcon from 'share-svgs/svgs/folder-category.svg'
import FolderIcon from 'share-svgs/svgs/folder.svg'
import NotepadIcon from 'share-svgs/svgs/notepad.svg'
import VideoIcon from 'share-svgs/svgs/video-icon.svg'
import { Box, BreadcrumbButtonsItem, HStack, Text } from 'ui'

type Props = {
    id: string
    name: string
    type: 'subscription' | 'folder' | 'categories' | 'session' | 'notepad'
    isSelected?: boolean
    isDisabled?: boolean
    handleOnClick(item: BreadcrumbButtonsItem): void
}

export const SelectBlock = ({
    id,
    name,
    type,
    isSelected,
    isDisabled,
    handleOnClick
}: Props) => {
    return (
        <Box
            as="button"
            className={classNames({
                selected: isSelected,
                disabled: isDisabled
            })}
            css={{
                width: '100%',
                maxWidth: '190px',
                height: '100rem',
                transition: 'background .3s',
                background: '$storm_gray',
                '& svg': {
                    height: '15rem',
                    mt: '2rem',
                    '& path': {
                        fill: type === 'notepad' ? 'white' : '$deep_aqua'
                    }
                },

                '&.selected': {
                    boxShadow: 'inset 0px 0px 0px 1rem #A7B7D1',
                    background: '$dark'
                },

                '&:hover': {
                    background: '$ivory',
                    color: '$raven_black',

                    '& svg path': {
                        fill: '$raven_black'
                    }
                },

                '&.disabled': {
                    background: '$dark_gray',
                    color: '$nobel',
                    pointerEvents: 'none',

                    '& svg path': {
                        fill: '$nobel'
                    }
                }
            }}
            p="2"
            bRadius={'1'}
            onClick={() => handleOnClick({ id, name })}
        >
            <HStack alignItems={'flexStart'} spacing="1">
                {type === 'folder' && <FolderIcon />}
                {type === 'categories' && <CategoryFolderIcon />}
                {type === 'session' && <VideoIcon />}
                {type === 'notepad' && <NotepadIcon />}
                <Text
                    size="1"
                    textTransform={'upper'}
                    weight={'bold'}
                    textAlign={'left'}
                    css={{
                        wordWrap: 'break-word',
                        width: '85%'
                    }}
                >
                    {name}
                </Text>
            </HStack>
        </Box>
    )
}
