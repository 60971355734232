import * as RadixCheckbox from '@radix-ui/react-checkbox'
import CheckIcon from 'share-svgs/svgs/checkmark.svg'
import { styled } from '../../theme/stitches.config'

const StyledCheckboxRoot = styled(RadixCheckbox.Root, {
    width: '20rem',
    height: '20rem',
    borderRadius: '4rem',
    border: '1rem solid $deep_aqua',
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    transition: '.3s',
    '&[data-state="checked"]': {
        border: '1rem solid $deep_aqua',
        background: '$deep_aqua'
    },
    '&:hover': {
        opacity: 0.8
    },
    '&:focus': {
        boxShadow: '0 0 0 2rem $blue'
    }
})

type Props = {
    disabled?: boolean
    required?: boolean
    checked?: boolean
    defaultChecked?: boolean
    name?: string
    onCheckedChange(checked: boolean): void
}

export const Checkbox = ({
    name,
    checked,
    required,
    disabled,
    defaultChecked,
    onCheckedChange
}: Props) => {
    return (
        <CheckboxRoot
            name={name}
            checked={checked}
            required={required}
            disabled={disabled}
            defaultChecked={defaultChecked}
            onCheckedChange={onCheckedChange}
        >
            <StyledCheckboxIndicator>
                <CheckIcon />
            </StyledCheckboxIndicator>
        </CheckboxRoot>
    )
}
const StyledCheckboxIndicator = styled(RadixCheckbox.Indicator, {
    '& svg': {
        display: 'flex',
        height: '20rem',

        path: {
            fill: '$ivory'
        }
    }
})

export const CheckboxRoot = StyledCheckboxRoot
