import { truncate } from 'lodash'
import { MutableRefObject } from 'react'
import { CSS, styled } from '../../theme/stitches.config'

type FileInputProps = {
    id: string
    fileInputRef: MutableRefObject<HTMLInputElement | null>
    fileName?: string
    testId?: string
    accept?: string
    reactFormRef?: (instance: any) => void
} & { css?: CSS; as?: any }

export const FileInput = ({
    id,
    as,
    css,
    fileName,
    fileInputRef,
    reactFormRef,
    accept = 'video/mp4,video/x-m4v,video/*,audio/*',
    testId,
    ...rest
}: FileInputProps) => {
    const handleChooseFileClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click()
        }
    }

    const clearInputValue = (event: any) => {
        event.target.value = ''
    }

    return (
        <FileInputContainerDiv as={as} data-testid={testId} css={css}>
            <FileInputLabel htmlFor={id}>
                choose an audio or video file to upload
            </FileInputLabel>
            <FileInputButton type="button" onClick={handleChooseFileClick}>
                <div className="name">
                    {truncate(fileName, {
                        length: 56
                    })}
                </div>
                <div className="choose-file">choose file</div>
            </FileInputButton>

            <input
                id={id}
                ref={(e) => {
                    reactFormRef && reactFormRef(e)
                    fileInputRef.current = e
                }}
                type="file"
                accept={accept}
                onClick={clearInputValue}
                hidden
                {...rest}
            />
        </FileInputContainerDiv>
    )
}

const FileInputContainerDiv = styled('div', {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
})

const FileInputLabel = styled('label', {
    fontSize: '$2',
    color: '$ivory',
    fontWeight: '$bold',
    textTransform: 'uppercase',
    letterSpacing: '$1',
    marginBottom: '4rem'
})

const FileInputButton = styled('button', {
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    outline: 'none',

    '.name': {
        maxWidth: '200rem',
        paddingLeft: '10rem',
        fontSize: '$2',
        borderTopLeftRadius: '$pill',
        borderBottomLeftRadius: '$pill',
        borderRight: 'none',
        color: '$ivory',
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        background: '$storm_gray',
        wordBreak: 'break-word',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: '$normal',

        '@md': {
            maxWidth: '100%'
        }
    },

    '.choose-file': {
        padding: '12rem 15rem',
        fontSize: '12rem',
        color: 'black',
        fontWeight: '$bold',
        letterSpacing: '1.5rem',
        textTransform: 'uppercase',
        borderTopRightRadius: '$pill',
        borderBottomRightRadius: '$pill',
        background: '$deep_aqua'
    }
})

FileInput.displayName = 'FileInput'
