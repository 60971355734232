import { ChevronDownIcon } from '@radix-ui/react-icons'
import * as Select from '@radix-ui/react-select'
import { styled } from 'ui/theme/stitches.config'

export default function IndustrySelect({
    onSelectIndustry
}: {
    onSelectIndustry: (value: string) => void
}) {
    return (
        <Select.Root
            onValueChange={(value) => {
                onSelectIndustry(value)
            }}
        >
            <StyledTrigger aria-label="Select Industry">
                <Select.Value placeholder="Select Industry" />
                <Select.Icon>
                    <ChevronDownIcon />
                </Select.Icon>
            </StyledTrigger>

            <Select.Portal>
                <StyledContent position="popper" sideOffset={8}>
                    <StyledViewport>
                        <Select.Group>
                            <StyledItem value="Account Management">
                                <Select.ItemText>
                                    Account Management
                                </Select.ItemText>
                            </StyledItem>
                            <StyledItem value="Customer Experience">
                                <Select.ItemText>
                                    Customer Experience
                                </Select.ItemText>
                            </StyledItem>
                            <StyledItem value="Digital & eCommerce">
                                <Select.ItemText>
                                    Digital & eCommerce
                                </Select.ItemText>
                            </StyledItem>
                            <StyledItem value="Entertainment">
                                <Select.ItemText>Entertainment</Select.ItemText>
                            </StyledItem>
                            <StyledItem value="Finance & Government">
                                <Select.ItemText>
                                    Finance & Government
                                </Select.ItemText>
                            </StyledItem>
                            <StyledItem value="Marketing & Operations">
                                <Select.ItemText>
                                    Marketing & Operations
                                </Select.ItemText>
                            </StyledItem>
                            <StyledItem value="People & Culture">
                                <Select.ItemText>
                                    People & Culture
                                </Select.ItemText>
                            </StyledItem>
                            <StyledItem value="Research & Insights">
                                <Select.ItemText>
                                    Research & Insights
                                </Select.ItemText>
                            </StyledItem>
                            <StyledItem value="Retail/Front-line Operations">
                                <Select.ItemText>
                                    Retail/Front-line Operations
                                </Select.ItemText>
                            </StyledItem>
                            <StyledItem value="UX & Product">
                                <Select.ItemText>UX & Product</Select.ItemText>
                            </StyledItem>
                            <StyledItem value="Other">
                                <Select.ItemText>Other</Select.ItemText>
                            </StyledItem>
                        </Select.Group>
                    </StyledViewport>
                </StyledContent>
            </Select.Portal>
        </Select.Root>
    )
}

const StyledTrigger = styled(Select.Trigger, {
    width: '100%',
    padding: '12px 14px',
    borderRadius: '30px',
    border: 'none',
    outline: 'none',
    backgroundColor: '$light_gray',
    fontSize: '14px',
    fontWeight: '500',
    color: 'black',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
})

const StyledContent = styled(Select.Content, {
    width: 'var(--radix-select-trigger-width)',
    backgroundColor: '$light_gray',
    borderRadius: '20px',
    overflow: 'scroll',
    zIndex: 9999,
    maxHeight: '300px'
})

const StyledViewport = styled(Select.Viewport, {
    padding: '8px'
})

const StyledItem = styled(Select.Item, {
    padding: '12px 20px',
    fontSize: '14px',
    color: 'black',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0px',

    '&[data-highlighted]': {
        backgroundColor: '$magenta_300',
        color: 'white',
        borderRadius: '30px',
        outline: 'none',
        boxShadow: 'none'
    },
    "&[data-state='checked']": {
        backgroundColor: '$magenta_300',
        color: 'white',
        borderRadius: '30px'
    },
    '&:focus': {
        outline: 'none',
        boxShadow: 'none'
    }
})
