import { styled } from '../../theme/stitches.config'

export const MuxAudioPlayerWrapper = styled('div', {
    width: '100%',
    'mux-player': {
        '--playback-rate-button': 'none',
        '--media-range-bar-color': '#0097A0',
        '--seek-backward-button': 'none',
        '--seek-forward-button': 'none'
    },
    'mux-player::part(time range)': {
        '--media-range-bar-color': '#0097A0',
        '--media-time-buffered-color': '#274955'
    },
    'mux-player::part(volume range)': {
        pr: '$2',
        '--media-range-bar-color': '#0097A0',
        '--media-range-track-background': '#274955'
    }
})
