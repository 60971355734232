import type React from 'react'
import { useState } from 'react'
import { useClearRefinements } from 'react-instantsearch'
import SearchFilterIcon from 'share-svgs/svgs/search-filter.svg'
import { styled } from 'ui/theme/stitches.config'
import { usePreventScroll } from '../../hooks/use-prevent-scroll'
import { Button } from '../Button'

interface PersistentDrawerProps {
    children: React.ReactNode
}

export default function PersistentDrawer({ children }: PersistentDrawerProps) {
    const { refine: clearRefinements } = useClearRefinements()
    const [isOpen, setIsOpen] = useState(false)
    usePreventScroll(isOpen)

    const handleClearRefinements = () => {
        clearRefinements()
        setIsOpen(false)
    }

    return (
        <>
            <Button
                css={{
                    padding: '10px 35px',
                    position: 'fixed',
                    bottom: '20px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 10
                }}
                onClick={() => setIsOpen(true)}
            >
                <SearchFilterIcon /> filters
            </Button>

            <Overlay isOpen={isOpen} onClick={() => setIsOpen(false)} />
            <DrawerContent isOpen={isOpen}>
                {children}
                <DrawerFooter>
                    <Button
                        color={'secondary'}
                        onClick={handleClearRefinements}
                    >
                        clear
                    </Button>
                    <Button onClick={() => setIsOpen(false)}>
                        show result
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </>
    )
}

const Overlay = styled('div', {
    position: 'fixed',
    inset: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 30,
    transition: 'all 0.3s ease-in-out',

    variants: {
        isOpen: {
            true: { opacity: 1, pointerEvents: 'auto' },
            false: { opacity: 0, pointerEvents: 'none' }
        }
    }
})

const DrawerContent = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'fixed',
    backgroundColor: '$slate_gray',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    overflowY: 'auto',
    transition: 'all 0.3s ease-in-out',
    zIndex: 50,
    left: 0,
    right: 0,
    bottom: 0,
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    height: '80svh',
    transform: 'translateY(100%)',

    variants: {
        isOpen: {
            true: { transform: 'translateY(0)' }
        }
    }
})

const DrawerFooter = styled('div', {
    backgroundColor: '$raven_black',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    marginTop: '10px',
    position: 'sticky',
    left: 0,
    right: 0,
    bottom: 0
})
