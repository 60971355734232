import classNames from 'classnames'
import { isEmpty } from 'lodash'
import { useRef, useState } from 'react'
import { useLocalStorage } from 'react-use'
import RadioItemButtonIcon from 'share-svgs/svgs/radio-item-button.svg'
import SearchOptionsIcon from 'share-svgs/svgs/search-option-icon.svg'
import SearchIcon from 'share-svgs/svgs/search.svg'
import {
    Box,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuRadioGroup,
    DropdownMenuRadioItem,
    DropdownMenuTrigger,
    Flex,
    Form,
    IconButton
} from 'ui'
import { styled } from '../../theme/stitches.config'
import { ArrowLeft } from '../Svgs/ArrowLeft'
import { useCurrentSearchPreference } from './current-search-preference'

export const SearchInput = ({
    query,
    searchPlaceholder = 'Search All',
    defaultInputValue = '',
    enableMobileSearch,
    disableSearchPreference,
    disableRecentSearches,
    disableOnChangeSearch,
    searchOnSubmit,
    customInputWidthOnDesktop = '320px',
    onSearch
}: {
    query: string
    searchPlaceholder?: string
    defaultInputValue?: string
    disableRecentSearches?: boolean
    disableSearchPreference?: boolean
    enableMobileSearch?: {
        tabletOrAbove: boolean
        searchInputIsActiveOnMobile: boolean
        setSearchInputIsActiveOnMobile?(isActive: boolean): void
    }
    searchOnSubmit?: boolean
    customInputWidthOnDesktop?: string
    disableOnChangeSearch?: boolean
    onSearch(query: string): void
}) => {
    const { setSearchPreference } = useCurrentSearchPreference()
    const { searchPreference } =
        useCurrentSearchPreference() ||
        JSON.parse(localStorage.searchPreference)
    const [inputValue, setInputValue] = useState(defaultInputValue)
    const globalSearchRef = useRef<HTMLInputElement>(null)

    const [recentSearches, setRecentSearches] = useLocalStorage<string[]>(
        'recent-searches',
        []
    )

    const setQuery = (newQuery: string) => {
        setInputValue(newQuery)
        searchPreference === 'exact'
            ? onSearch(`"${newQuery}"`)
            : onSearch(newQuery)
    }

    const handleUpdateRecentSearches = (searchQuery: string) => {
        const updateRecentSearches =
            recentSearches && !isEmpty(recentSearches)
                ? [
                      searchQuery,
                      ...recentSearches.filter(
                          (item: string) => item !== searchQuery
                      )
                  ].slice(0, 5)
                : [searchQuery]
        setRecentSearches(updateRecentSearches)
    }

    const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        event.stopPropagation()
        if (searchOnSubmit) {
            searchPreference === 'exact'
                ? onSearch(`"${inputValue}"`)
                : onSearch(inputValue)
        }

        if (query.trim() !== '' && !disableRecentSearches) {
            handleUpdateRecentSearches(query.replace(/"/g, ''))
        }
    }

    const handleSetSearchPreference = (value: string) => {
        const searchPreference = value as 'partial' | 'exact'
        setSearchPreference(searchPreference)
        searchPreference === 'exact' && onSearch(`"${query}"`)
        searchPreference === 'partial' && onSearch(query.replace(/"/g, ''))
    }

    const handleSearchInputOnChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        disableOnChangeSearch
            ? setInputValue(event.target.value)
            : setQuery(event.target.value)
    }

    return (
        <Box
            direction={'row'}
            css={{
                width: '100%',
                '@md': {
                    width: customInputWidthOnDesktop
                }
            }}
            relative
        >
            {enableMobileSearch &&
                enableMobileSearch.searchInputIsActiveOnMobile &&
                !enableMobileSearch.tabletOrAbove && (
                    <ArrowLeft
                        onClick={() =>
                            enableMobileSearch.setSearchInputIsActiveOnMobile
                                ? enableMobileSearch.setSearchInputIsActiveOnMobile(
                                      false
                                  )
                                : null
                        }
                        width={20}
                        cursor={'pointer'}
                        style={{
                            marginRight: '10px'
                        }}
                    />
                )}
            <Form
                css={{
                    padding: '8px 12px',
                    borderRadius: '$pill',
                    background: '$tuna',
                    '&:focus-within': {
                        boxShadow: '0 0 0 1px #0097A0'
                    },
                    '&.hidden': {
                        display: 'none'
                    }
                }}
                className={classNames({
                    hidden:
                        enableMobileSearch &&
                        !enableMobileSearch.searchInputIsActiveOnMobile &&
                        !enableMobileSearch.tabletOrAbove
                })}
                role="search"
                noValidate
                onSubmit={handleOnSubmit}
            >
                <Flex justifyContent={'flexStart'}>
                    {!disableSearchPreference && (
                        <SearchDropdown
                            searchPreference={searchPreference}
                            handleSetSearchPreference={
                                handleSetSearchPreference
                            }
                        />
                    )}

                    <StyledInput
                        ref={globalSearchRef}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        placeholder={searchPlaceholder}
                        spellCheck={false}
                        maxLength={512}
                        type="search"
                        value={inputValue}
                        onChange={handleSearchInputOnChange}
                        autoFocus
                    />

                    <IconButton
                        aria-label={'search'}
                        type="submit"
                        css={{
                            $$iconHeight: '20px',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)'
                        }}
                    >
                        <SearchIcon />
                    </IconButton>
                </Flex>
            </Form>

            {enableMobileSearch &&
                !enableMobileSearch.searchInputIsActiveOnMobile &&
                !enableMobileSearch.tabletOrAbove && (
                    <IconButton
                        css={{
                            $$iconHeight: '20px',
                            marginLeft: 'auto'
                        }}
                        onClick={() =>
                            enableMobileSearch.setSearchInputIsActiveOnMobile
                                ? enableMobileSearch.setSearchInputIsActiveOnMobile(
                                      true
                                  )
                                : null
                        }
                    >
                        <SearchIcon />
                    </IconButton>
                )}
        </Box>
    )
}

const StyledInput = styled('input', {
    border: 'none',
    outline: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '$ivory',
    paddingRight: '20px',
    fontSize: '$3',
    display: 'flex',
    flex: '1 1 0%',
    background: '$tuna',
    '&::-webkit-search-cancel-button': {
        '-webkit-appearance': 'none',
        appearance: 'none',
        display: 'none'
    },
    /* Hide clear button in Internet Explorer */
    '&::-ms-clear': {
        display: 'none'
    }
})

const SearchDropdown = ({
    searchPreference = 'partial',
    handleSetSearchPreference
}: {
    searchPreference?: 'partial' | 'exact'
    handleSetSearchPreference(value: string): void
}) => {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <IconButton
                    aria-label={'search options'}
                    css={{
                        mr: '5px',
                        borderRight: '2px solid $gray',
                        paddingRight: '10px',
                        '& svg': {
                            height: '20px'
                        }
                    }}
                >
                    <SearchOptionsIcon />
                </IconButton>
            </DropdownMenuTrigger>

            <DropdownMenuContent
                align="start"
                alignOffset={-8}
                sideOffset={12}
                style={{
                    backgroundColor: '$raven_black',
                    minWidth: '150px',
                    paddingBottom: '10px',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px'
                }}
            >
                <DropdownMenuLabel
                    css={{ fontSize: '$2', color: '$ivory', p: '10px' }}
                >
                    Search Option
                </DropdownMenuLabel>
                <DropdownMenuRadioGroup
                    value={searchPreference}
                    onValueChange={handleSetSearchPreference}
                >
                    <DropdownMenuRadioItem value={'partial'}>
                        <RadioItemButtonIcon /> Partial Match
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem value={'exact'}>
                        <RadioItemButtonIcon /> Exact Match
                    </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
