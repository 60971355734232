import useMergedRef from '@react-hook/merged-ref'
import classNames from 'classnames'
import {
    ComponentProps,
    ElementRef,
    forwardRef,
    useEffect,
    useRef,
    useState
} from 'react'
import { CSS, styled } from '../../theme/stitches.config'
import { Text } from '../Text'

const DEFAULT_TAG = 'textarea'

type Props = {
    id?: string
    label?: string
    error?: boolean
    errorMsg?: string
    animatedLabel?: boolean
    handleOnInputClick?: () => void
} & { css?: CSS; as?: any; containerCss?: CSS } & ComponentProps<
        typeof DEFAULT_TAG
    >

export const TextArea = forwardRef<ElementRef<typeof DEFAULT_TAG>, Props>(
    (props, forwardRef) => {
        const {
            id,
            label,
            error,
            errorMsg,
            disabled,
            containerCss,
            className,
            css,
            animatedLabel,
            handleOnInputClick,
            ...inputProps
        } = props

        const innerRef = useRef<HTMLTextAreaElement | null>(null)
        const multiRef = useMergedRef(forwardRef, innerRef)
        const [labelAnimated, setLabelAnimated] = useState(false)

        const handleInputClick = () => {
            handleOnInputClick && handleOnInputClick()

            if (!labelAnimated) {
                setLabelAnimated(true)
            }
        }

        const handleOnFocus = () => {
            if (!labelAnimated) {
                setLabelAnimated(true)
            }
        }

        useEffect(() => {
            if ((innerRef.current && innerRef.current.value) || animatedLabel) {
                if (disabled) {
                    setLabelAnimated(true)
                }

                setLabelAnimated(true)
            }
        }, [disabled, setLabelAnimated, animatedLabel])

        return (
            <Container css={containerCss}>
                <Label
                    htmlFor={id}
                    className={classNames({
                        animated: labelAnimated,
                        disabled: disabled
                    })}
                >
                    {label}
                </Label>
                <StyledTextArea
                    id={id}
                    css={css}
                    className={`${classNames({
                        error: error
                    })} ${className ? className : ''}`}
                    ref={multiRef}
                    disabled={disabled}
                    onClick={handleInputClick}
                    onFocus={handleOnFocus}
                    onBlur={handleOnFocus}
                    {...inputProps}
                />
                {errorMsg ? (
                    <Text size={1} color="error" textTransform="upper">
                        {errorMsg}
                    </Text>
                ) : null}
            </Container>
        )
    }
)

TextArea.displayName = 'Textarea'

const Container = styled('div', {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '120rem'
})

const Label = styled('label', {
    color: '$cyan',
    fontSize: '$3',
    position: 'relative',
    top: '25rem',
    left: '12rem',
    cursor: 'text',
    transition: '.5s ease',

    '&.animated': {
        fontSize: '$2',
        top: '0',
        left: '0'
    }
})

const StyledTextArea = styled('textarea', {
    appearance: 'none',
    borderWidth: '0',
    fontFamily: 'inherit',
    margin: '0',
    outline: 'none',
    borderRadius: '$3',
    p: '10rem',
    background: '$storm_gray',
    width: '100%',
    minHeight: '100rem',
    color: '$ivory',
    resize: 'none',
    fontSize: '$3',
    '&:focus, &:focus-visible': {
        outline: 'none',
        border: '1rem solid $deep_aqua'
    },
    '&::placeholder': {
        color: '$nobel'
    },
    '&.error': {
        borderColor: '$orange'
    }
})
