import { useRouter } from 'next/router'
import { ComponentProps, ReactNode, useEffect, useState } from 'react'
import { CSS, styled } from 'ui/theme/stitches.config'
import { Heading } from '../Heading'
import { VStack } from '../Layout'
import { Text } from '../Text'

const DEFAULT_TAG = 'button'

type Props = {
    title: string
    isNotepad?: boolean
    description?: string
    imgSrc?: string
    svg?: ReactNode
    alt?: string
} & ComponentProps<typeof DEFAULT_TAG> & { css?: CSS; as?: any }

export const FileButton = ({
    svg,
    imgSrc,
    alt,
    title,
    description,
    ...props
}: Props) => {
    const router = useRouter()

    const [searchQuery, setSearchQuery] = useState(router.query.query || '')

    const getHighlightedText = (text: string) => {
        const escapedHighlight = `${searchQuery}`.replace(
            /[-\/\\^$*+?.()|[\]{}]/g,
            '\\$&'
        )
        const parts = text?.split(new RegExp(`(${escapedHighlight})`, 'gi'))

        return parts?.map((part, i) => (
            <span
                key={i}
                style={
                    part.toLowerCase() === `${searchQuery}`.toLowerCase()
                        ? {
                              color: '#0097A0',
                              fontWeight: 'bold',
                              fontStyle: 'normal',
                              textTransform: 'uppercase'
                          }
                        : {}
                }
            >
                {part}
            </span>
        ))
    }

    useEffect(() => {
        if (router.isReady) {
            if (router.query && router.query.query) {
                setSearchQuery(router.query.query)
            }
        }
    }, [router.isReady])

    return (
        <StyledFileButton {...props}>
            {svg}
            {imgSrc && alt ? (
                <ImgContainer>
                    <img
                        src={imgSrc}
                        width={40}
                        height={40}
                        alt={alt}
                        style={{
                            objectFit: 'cover'
                        }}
                    />
                </ImgContainer>
            ) : null}

            <VStack alignItems="flexStart" spacing="1">
                <Heading
                    as="h3"
                    size="3"
                    textTransform="upper"
                    textAlign={'left'}
                    css={{
                        wordBreak: 'break-word',
                        overflowWrap: 'break-word'
                    }}
                >
                    {searchQuery ? getHighlightedText(title) : title}
                </Heading>
                {description ? (
                    <Text
                        as="p"
                        size="1"
                        color="ivory"
                        textAlign="left"
                        weight="light"
                        className={'description'}
                        css={{
                            wordBreak: 'break-word',
                            overflowWrap: 'break-word'
                        }}
                    >
                        {searchQuery
                            ? getHighlightedText(description)
                            : description}
                    </Text>
                ) : null}
            </VStack>
        </StyledFileButton>
    )
}

const StyledFileButton = styled('button', {
    $$svgWidth: '40rem',
    width: '100%',
    p: '$2',
    display: 'flex',
    alignItems: 'center',
    border: '1rem solid $deep_aqua',
    borderRadius: '$1',
    letterSpacing: '$1',
    transition: '.3s',

    '& svg': {
        width: '$$svgWidth',
        mr: '$2',
        display: 'flex',
        flexShrink: '0'
    },

    '&:not(.pressedButton):hover': {
        color: '$ivory'
    },

    '&.pressedButton': {
        background: '$deep_aqua_30'
    },
    variants: {
        isNotepad: {
            true: {
                '&:not(.pressedButton):hover': {
                    background: '$deep_aqua_30'
                }
            },
            false: {
                '&:not(.pressedButton):hover': {
                    background: '$deep_aqua'
                }
            }
        }
    },
    defaultVariants: {
        isNotepad: false
    }
})

const ImgContainer = styled('div', {
    mr: '$2',
    display: 'flex',
    flexShrink: '0'
})
